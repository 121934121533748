import React, { useEffect } from 'react';
import Layout from './Layout';
import Welcome from '../components/Welcome';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../features/AuthUser.js';

function Dashboard() {
  const navigate = useNavigate();
  const { token } = AuthUser();

  useEffect(()=>{
    if (!token) {
      navigate('/login');
    }
  })

  return (
    <div>
      <Layout>
        <Welcome />
      </Layout>
    </div>
  )
}

export default Dashboard
