import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import axios from "axios";
import "bootstrap/dist/js/bootstrap.min.js";
import "./static/css/app.css";

axios.defaults.withCredentials = false;

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

