import React, { useEffect } from "react";
import Layout from "../Layout";
import UserProfile from "../../components/User/UserProfile";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../features/AuthUser";

const Profile = () => {
  const navigate = useNavigate();
  const { token } = AuthUser();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  });
  return (
    <Layout>
      <UserProfile />
    </Layout>
  );
};

export default Profile;
