import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import AuthUser from "../../features/AuthUser";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";

const DestinyReport = () => {
  const { http } = AuthUser();
  const [bookings, setBookings] = useState([]);
  const [travellers, setTravellers] = useState([]);
  const [partners, setPartners] = useState([]);
  const [destiny, setDestiny] = useState([]);
  const [people, setPeople] = useState([]);

  const options = { filterType: "checkbox" };

  const columns = [
    {
      name: "name",
      label: "Destiny",
    },
    {
      name: "total",
      label: "Number of Bookings",
    },
  ];

  const columns1 = [
    {
      name: "name",
      label: "Destiny",
    },
    {
      name: "total",
      label: "Number of Travellers",
    },
  ];

  useEffect(() => {
    const fecthPoepleByActivity = async () => {
      await http.get("/destiny").then((response) => {
        setBookings(response.data.bookings);
        setTravellers(response.data.travellers);
      });
    };
    fecthPoepleByActivity();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-body">
              <MUIDataTable
                title={"Total de reservas por Destino"}
                data={bookings}
                columns={columns}
              />
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-body">
              <ResponsiveContainer width="100%" aspect={2}>
                <BarChart
                  width={500}
                  height={300}
                  data={bookings}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" stroke="#000" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="total"
                    barSize={15}
                    fill="#413ea0"
                    label={{ position: "top" }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-body">
              <MUIDataTable
                title={"Total de viajeros por Destino"}
                data={travellers}
                columns={columns1}
              />
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-body">
              <ResponsiveContainer width="100%" aspect={2}>
                <BarChart
                  width={500}
                  height={300}
                  data={travellers}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" stroke="#000" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="total"
                    barSize={15}
                    fill="#82E0AA"
                    label={{ position: "top" }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DestinyReport;
