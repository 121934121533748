import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Swal from "sweetalert2";
import AuthUser from "../../features/AuthUser";
import { Button } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const FormAddBooking = () => {
  const { http, user } = AuthUser();
  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [oneDate, setOneDate] = useState(new Date());
  const [code, setCode] = useState("");
  const user_id = user.uuid;
  const [activityId, setActivityId] = useState("");
  const [payment, setPayment] = useState("");
  const [people, setPeople] = useState(0);
  const [total, setTotal] = useState(0);

  const [parnerts, setParnerts] = useState([
    {
      typeId: "",
      numId: "",
      name: "",
      last_name: "",
      age: null,
    },
  ]);

  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const [options, setOptions] = useState([]);
  const [types, setTypes] = useState();
  const [activities, setActivities] = useState([]);
  const [price, setPrice] = useState(0);

  const [toggle, setToggle] = useState(false);
  const [showCalendar, setShowCalendar] = useState("");
  const [numPeople, setNumPeople] = useState(false);

  const fetchData = async () => {
    const response = await http.get("/types");
    setOptions(
      response.data.map((option) => ({
        label: option.name,
        value: option.id,
      }))
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTypeChange = useCallback((typeId) => {
    async function fetchData() {
      const response = await http.get(`/getActivities/${typeId}`);

      setActivities(response.data);

      if (typeId == 1) {
        setShowCalendar("Plan");
      } else if (typeId == 2) {
        setShowCalendar("Pasadia");
      }
    }
    fetchData();
  }, []);

  const onChange = (date, dateString) => {
    setOneDate(dateString);
  };

  const onChangeRange = (dates, dateStrings) => {
    setDateRange(dateStrings);
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const renderCalendar = () => {
    if (showCalendar === "Plan") {
      return (
        <Space direction="vertical" size={12}>
          <RangePicker
            defaultValue={moment()}
            format={dateFormat}
            onChange={onChangeRange}
            disabledDate={disabledDate}
          />
        </Space>
      );
    } else {
      return (
        <DatePicker
          format={dateFormat}
          onChange={onChange}
          disabledDate={disabledDate}
        />
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await http.get("/bookcode");
      let newCode = 0;
      if (data.code == null) {
        newCode = 1;
      } else {
        let code = parseInt(data.code.LASTCODE, 10);
        newCode = code + 1;
      }
      let invoice = newCode.toString().padStart(4, 0);
      setCode("DVB-" + invoice);
    };
    fetchData();
  }, []);

  async function handleActivityChange(actId) {
    const { data } = await http.get(`/getActivity/${actId}`);
    setActivityId(actId);
    setPeople(1);
    setPrice(data.price);
    setTotal(data.price);
    setNumPeople(true);
  }

  const handlePartnersChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...parnerts];
    list[index][name] = value;
    setParnerts(list);
  };

  function removePeopleChange(q) {
    setPeople((prevPeople) => prevPeople + q);
    let person = people + q;
    if (person <= 0) {
      alert("The number of people must be equal to or greater than 1");
      setPeople(1);
    } else if (person > 1) {
      handleRemoveClick();
    } else if (person <= 1) {
      setToggle(false);
    }
  }

  function addPeopleChange(q) {
    setPeople((prevPeople) => prevPeople + q);
    let person = people + q;
    if (person > 1) {
      setToggle(true);
    }
    if (person > 2) {
      handleAddClick();
    }
  }

  const dateConverter = (startDate, endDate) => {
    let result = moment(endDate).diff(startDate, "days");
    return result;
  };

  useEffect(() => {
    setTotal(people * price);
    if (showCalendar === "Planes") {
      if (dateRange) {
        let totalDays = dateConverter(startDate, endDate);
        setTotal(totalDays * price * people);
      }
    }
  });

  const handleAddClick = () => {
    setParnerts([
      ...parnerts,
      {
        typeId: "",
        numId: "",
        name: "",
        last_name: "",
        age: null,
      },
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...parnerts];
    list.splice(index, 1);
    setParnerts(list);
  };

  const saveBook = async (e) => {
    e.preventDefault();
    try {
      if (showCalendar === "Plan") {
        const response = await http.post("/bookings", {
          code: code,
          user_id: user_id,
          activity_id: activityId,
          checkin: moment(startDate).format("YYYY-MM-DD"),
          checkout: moment(endDate).format("YYYY-MM-DD"),
          payment: payment,
          quantity: people,
          total: total,
          partners: parnerts,
        });

        if (response) {
          navigate("/bookings-by-user");
        } else {
          navigate("/booking");
        }
      } else if (showCalendar === "Pasadia") {
        const response = await http.post("/bookings", {
          code: code,
          user_id: user_id,
          activity_id: activityId,
          checkin: moment(oneDate).format("YYYY-MM-DD"),
          checkout: moment(oneDate).format("YYYY-MM-DD"),
          payment: payment,
          quantity: people,
          total: total,
          partners: parnerts,
        });

        if (response) {
          navigate("/bookings-by-user");
        } else {
          navigate("/booking");
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="row d-flex justify-content-center align-items-center">
        {/* <div className="col-12 col-md-4 col-xxl-3 d-flex order-1 order-xxl-1">
          <div
            className="card flex-fill w-100"
            style={{
              backgroundImage: `url("/img/playa-rio.jpg")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          ></div>
        </div> */}

        <div className="col-12 col-md-9 col-lg-7 col-xl-9">
          <div className="card flex-fill">
            <div className="card-header">
              <h5 className="card-title mb-0 text-center text-uppercase">
                Reservas Disfruta Buenaventura
              </h5>
            </div>
            <div className="card-body d-flex">
              <div className="align-self-center w-100">
                <form
                  onSubmit={saveBook}
                  className="row justify-content-md-center border border-dark"
                >
                  <p className="text-center">{msg}</p>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control"
                      id="code"
                      name="code"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      hidden
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Planes o Pasadias</label>
                    <select
                      name="type"
                      value={types}
                      onChange={(e) => handleTypeChange(e.target.value)}
                      className="form-control form-control-lg"
                    >
                      <option value="none" disabled selected>
                        {" "}
                        -- Selecione una opcion --{" "}
                      </option>
                      {options.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Elija la Actividad</label>
                    <select
                      name="activityId"
                      value={activityId}
                      onChange={(e) => handleActivityChange(e.target.value)}
                      className="form-control form-control-lg"
                    >
                      <option value="none" disabled selected>
                        {" "}
                        -- Selecione una opcion --{" "}
                      </option>
                      {activities.map((activity) => {
                        return (
                          <option key={activity.uuid} value={activity.uuid}>
                            {activity.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Seleccione una fecha</label>
                    <div>{renderCalendar()}</div>
                  </div>
                  <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Metodo de Pago</label>
                    <select
                      name="payment"
                      value={payment}
                      onChange={(e) => setPayment(e.target.value)}
                      className="form-control form-control-lg"
                    >
                      <option selected>Seleccione una opcion</option>
                      <option value="Transfer">Transferencia</option>
                      <option value="Cash">Efectivo</option>
                    </select>
                  </div>
                  <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Numero de personas</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="quantity"
                      name="people"
                      value={people}
                      onChange={(e) => setPeople(e.target.value)}
                      disabled
                      min={1}
                    />
                    {numPeople === true && (
                      <>
                        <Button
                          onClick={() => removePeopleChange(-1)}
                          variant="contained"
                          color="error"
                        >
                          <Remove />
                        </Button>
                        <Button
                          onClick={() => addPeopleChange(1)}
                          variant="contained"
                          color="success"
                        >
                          <Add />
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Total</label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      id="total"
                      name="total"
                      value={total}
                      onChange={(e) => setTotal(e.target.value)}
                      disabled
                      min={0}
                    />
                  </div>
                  <br />
                  <hr />
                  <h3 className="card-subtitle text-center text-uppercase">
                    Datos de Acompañantes
                  </h3>
                  <hr />
                  <div className="col-md-12 col-sm-12 table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th>Tipo Identificacion</th>
                          <th>Numero Identificacion</th>
                          <th>Nombres</th>
                          <th>Apellidos</th>
                          <th>Edad: Si es menor de 18</th>
                        </tr>
                      </thead>
                      {toggle === true && (
                        <tbody>
                          {parnerts.map((parnert, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <select
                                    className="form-control"
                                    id="typeId"
                                    name="typeId"
                                    value={parnerts.typeId}
                                    onChange={(e) =>
                                      handlePartnersChange(e, index)
                                    }
                                  >
                                    <option disabled selected>
                                      Escoja una opcion
                                    </option>
                                    <option value="CC">
                                      Cedula de Ciudadania
                                    </option>
                                    <option value="CE">
                                      Cedula de Extranjeria
                                    </option>
                                    <option value="PP">Pasaporte</option>
                                    <option value="TI">
                                      Tarjeta de Identidad
                                    </option>
                                    <option value="RN">
                                      Registro de Nacimiento
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="numId"
                                    placeholder="DNI"
                                    name="numId"
                                    value={parnerts.numId}
                                    onChange={(e) =>
                                      handlePartnersChange(e, index)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="First Name"
                                    name="name"
                                    value={parnerts.name}
                                    onChange={(e) =>
                                      handlePartnersChange(e, index)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Last Name"
                                    name="last_name"
                                    value={parnerts.last_name}
                                    onChange={(e) =>
                                      handlePartnersChange(e, index)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="number"
                                    placeholder="Age If it's a child"
                                    name="age"
                                    value={parnerts.age}
                                    onChange={(e) =>
                                      handlePartnersChange(e, index)
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>

                  <div className="text-center p-2">
                    <Button type="submit" variant="contained" color="success">
                      Reservar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormAddBooking;
