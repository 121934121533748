import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import AuthUser from "../features/AuthUser";
import { Avatar, Stack } from "@mui/material";
import { AccountCircle, PersonOutline, DateRange } from "@mui/icons-material";

const NavigationBar = (props) => {
  const { user, token, logout } = AuthUser();
  const fullname = user.name + " " + user.last_name;

  const logoutUser = () => {
    if (token !== undefined) {
      logout();
    }
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-light navbar-bg">
        <a className="sidebar-toggle js-sidebar-toggle" onClick={props.onClick}>
          <i className="hamburger align-self-center" />
        </a>
        <a href="/">
          <img
            src="../logo_disfruta_buenaventura.png"
            alt="Logo"
            width={62}
            height={62}
          />
        </a>
        <div className="navbar-collapse collapse">
          <ul className="navbar-nav navbar-align">
            {token ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link  d-none d-sm-inline-block"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <Avatar
                    alt={fullname}
                    className="avatar"
                    {...stringAvatar(fullname)}
                  />
                  {/* <span className="text-dark">{user.last_name}</span> */}
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <Link to="/profile" className="dropdown-item">
                    <PersonOutline
                      className="align-middle me-1"
                      sx={{ fontSize: 20 }}
                    />
                    Profile
                  </Link>
                  {user && user.role === "admin" ? (
                    <Link to="/bookings" className="dropdown-item">
                      <DateRange
                        className="align-middle me-1"
                        sx={{ fontSize: 20 }}
                      />
                      Bookings
                    </Link>
                  ) : (
                    <Link to="/bookings-by-user" className="dropdown-item">
                      <DateRange
                        className="align-middle me-1"
                        sx={{ fontSize: 20 }}
                      />
                      Bookings
                    </Link>
                  )}

                  <div className="dropdown-divider" />
                  <button
                    onClick={logoutUser}
                    className="dropdown-item btn bg-success"
                  >
                    Log out
                  </button>
                </div>
              </li>
            ) : (
              <li>
                <Link to="/login">
                  <Button variant="success" size="sm">
                    Login
                  </Button>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavigationBar;
