import React, { useEffect } from "react";
import Layout from "../Layout";
import ProviderList from "../../components/Provider/ProviderList";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../features/AuthUser";

const Providers = () => {
  const navigate = useNavigate();
  const { token } = AuthUser();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  });

  return (
    <Layout>
      <ProviderList />
    </Layout>
  );
};

export default Providers;
