import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthUser from "../../features/AuthUser";

const FormAddUser = () => {
  const { http } = AuthUser();
  const [meter, setMeter] = useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");

  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g;
  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*+_-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{8,}/g; // eight characters or more

  const passwordTracker = {
    uppercase: password.match(atLeastOneUppercase),
    lowercase: password.match(atLeastOneLowercase),
    number: password.match(atLeastOneNumeric),
    specialChar: password.match(atLeastOneSpecialChar),
    eightCharsOrGreater: password.match(eightCharsOrMore),
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  const saveUser = async (e) => {
    e.preventDefault();
    try {
        const response = await http.post("/users", {
            name: name,
            last_name: lastName,
            email: email,
            password: password,
            phone: phone,
            role: role,
        });

        if (response) {
          navigate("/users");
        } else {
          navigate("/user");
          setName("");
          setLastName("");
          setEmail("");
          setPassword("");
          setPhone("");
          setRole("");
        }
    } catch (error) {
      console.log(error)
      if (error.response.status === 402) {
        Swal.fire({
          text: "User is already registered, please login",
          icon: "error",
        });
      }else {
        Swal.fire({
          text: "Error, data cannot be recorded",
          icon: "error",
        });
      }
      
    }
  };


  return (
    <>
      <h1 className="h3 mb-3">Add User</h1>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form
                onSubmit={saveUser}
                className="row g-3 justify-content-md-center"
              >
                <div className="col-md-6">
                  <label className="form-label">Nombres</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Apellidos</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Email</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Contraseña</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onFocus={() => setMeter(true)}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  {meter && (
                    <div>
                      <div className="password-strength-meter"></div>
                      <div>
                        {passwordStrength < 5 && "Must contain "}
                        {!passwordTracker.uppercase && "uppercase, "}
                        {!passwordTracker.lowercase && "lowercase, "}
                        {!passwordTracker.specialChar && "special character, "}
                        {!passwordTracker.number && "number, "}
                        {!passwordTracker.eightCharsOrGreater &&
                          "eight characters or more"}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <label className="form-label">Numero de Telefono</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Contact Number"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Role</label>
                  <div className="control">
                    <div className="control">
                      <select
                        id="role"
                        className="form-control"
                        name="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option value="choose" selected>
                          Choose one option
                        </option>
                        <option value="admin">Admin</option>
                        <option value="operator">Operator</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                  >
                    Save
                  </button>
                </div>
              </form>
              <style jsx>
                {`
                  .password-strength-meter {
                    height: 0.3rem;
                    background-color: lightgrey;
                    border-radius: 3px;
                    margin: 0.5rem 0;
                  }

                  .password-strength-meter::before {
                    content: "";
                    background-color: ${[
                      "red",
                      "orange",
                      "#03a2cc",
                      "#03a2cc",
                      "#0ce052",
                    ][passwordStrength - 1] || ""};
                    height: 100%;
                    width: ${(passwordStrength / 5) * 100}%;
                    display: block;
                    border-radius: 3px;
                    transition: width 0.2s;
                  }
                `}
              </style>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormAddUser;
