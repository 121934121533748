import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import AuthUser from "../../features/AuthUser";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  LineChart,
  Line,
  PieChart,
  Pie,
  Sector,
  Cell,
  Area,
  AreaChart,
} from "recharts";

const GraphicsReports = () => {
  const { http } = AuthUser();

  const [destiny, setDestiny] = useState([]);
  const [currentMonth, setCurrentMonth] = useState([]);
  const [bookStatus, setBookStatus] = useState([]);
  const [countries, setCountries] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [travellers, setTravellers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [pasadias, setPasadias] = useState([]);

  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];

  useEffect(() => {
    const getDestiny = async () => {
      await http.get("/destiny").then((response) => {
        setDestiny(response.data.bookings);
      });
    };
    getDestiny();
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      await http.get("/chart-type-activity").then((response) => {
        setPlans(response.data.plans);
        setPasadias(response.data.pasadias);
      });
    };
    getCountries();
  }, []);

  useEffect(() => {
    const getCurrentMonth = async () => {
      await http.get("/currentMonth").then((response) => {
        setCurrentMonth(response.data);
      });
    };
    getCurrentMonth();
  }, []);

  useEffect(() => {
    const getBookStatus = async () => {
      await http.get("/bookStatus").then((response) => {
        setBookStatus(response.data);
      });
    };
    getBookStatus();
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      await http.get("/chart-countries").then((response) => {
        setCountries(response.data);
      });
    };
    getCountries();
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      await http.get("/chart-per-months").then((response) => {
        setBookings(response.data.bookings);
        setTravellers(response.data.travellers);
      });
    };
    getCountries();
  }, []);

  const COLORS = ["#42DC39", "#FFBB28", "#0088FE", "#FF3240"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title">Number of travelers per days</h5>
              <h6 className="card-subtitle text-muted">
                Travelers per days for the current month
              </h6>
            </div>
            <div className="card-body">
              <div className="chart">
                <ResponsiveContainer width="100%" height={200} aspect={2}>
                  <LineChart
                    width={500}
                    height={300}
                    data={currentMonth}
                    margin={{
                      top: 5,
                      right: 5,
                      left: 5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="fecha" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="people"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              {/* <table className="table mb-0">
                {currentMonth.map((booking) => (
                  <tbody>
                    <tr>
                      <td>Day of currentMonth {booking.fecha}</td>
                      <td className="text-end">{booking.people}</td>
                    </tr>
                  </tbody>
                ))}
              </table> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Reservations by destination</h5>
              <h6 className="card-subtitle text-muted">
                Number of reservations by destination
              </h6>
            </div>
            <div className="card-body">
              <div className="chart">
                <ResponsiveContainer width="100%" height={200} aspect={2}>
                  <BarChart
                    width={500}
                    height={300}
                    data={destiny}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" stroke="#000" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" barSize={25} fill="#413ea0" label={{ position: "top" }} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              {/* <table className="table mb-0">
                {destiny.map((booking) => (
                  <tbody>
                    <tr>
                      <td>{booking.name}</td>
                      <td className="text-end">{booking.total}</td>
                    </tr>
                  </tbody>
                ))}
              </table> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Country of origin</h5>
              <h6 className="card-subtitle text-muted">
                Country of origin of travelers
              </h6>
            </div>
            <div className="card-body">
              <div className="chart chart-sm">
                <ResponsiveContainer width="100%" height={200} aspect={2}>
                  <BarChart
                    width={500}
                    height={300}
                    data={countries}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" stroke="#000" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" barSize={25} fill="#82ca9d" label={{ position: "top" }} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              {/* <table className="table mb-0">
                {countries.map((country) => (
                  <tbody>
                    <tr>
                      <td>{country.name}</td>
                      <td className="text-end">{country.total}</td>
                    </tr>
                  </tbody>
                ))}
              </table> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Bookings Status</h5>
              <h6 className="card-subtitle text-muted">
                Status Percentage of Bookings Made
              </h6>
            </div>
            <div className="card-body">
              <div className="chart chart-sm">
                <ResponsiveContainer width="100%" height="50%" aspect={2}>
                  <PieChart width={500} height={300}>
                    <Pie
                      data={bookStatus}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={100}
                      fill="#8884d8"
                      dataKey="reservas"
                    >
                      {bookStatus.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
             {/*  <table className="table mb-0">
                {bookStatus.map((booking) => (
                  <tbody>
                    {booking.name === "Pending" ? (
                      <tr>
                        <td>
                          <span className="badge bg-warning"></span>
                        </td>
                        <td className="text-end">{booking.reservas}</td>
                      </tr>
                    ) : booking.name === "Accepted" ? (
                      <tr>
                        <td>
                          <span className="badge bg-info">Accepted</span>
                        </td>
                        <td className="text-end">{booking.reservas}</td>
                      </tr>
                    ) : booking.name === "Completed" ? (
                      <tr>
                        <td>
                          <span className="badge bg-success">Comlpeted</span>
                        </td>
                        <td className="text-end">{booking.reservas}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          <span class="badge bg-danger">Cancelled</span>
                        </td>
                        <td className="text-end">{booking.reservas}</td>
                      </tr>
                    )}
                  </tbody>
                ))}
              </table> */}
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Bookings per Months</h5>
              <h6 className="card-subtitle text-muted">
                Number of reservations per months
              </h6>
            </div>
            <div className="card-body">
              <div className="chart chart-sm">
                <ResponsiveContainer width="100%" height="100%" aspect={2}>
                  <ComposedChart
                    width={500}
                    height={200}
                    data={bookings}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 15,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="mes" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="reservas" barSize={20} fill="#413ea0" label={{ position: "top" }} />
                    <Line type="monotone" dataKey="reservas" stroke="#ff7300" />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Travellers per months</h5>
              <h6 className="card-subtitle text-muted">
                Numbers of travellers per months
              </h6>
            </div>
            <div className="card-body">
              <div className="chart chart-lg">
                <ResponsiveContainer width="100%" aspect={2}>
                  <BarChart
                    width={500}
                    height={300}
                    data={travellers}
                    margin={{
                      top: 15,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="mes" stroke="#000" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="travellers" barSize={25} fill="#82ca9d" label={{ position: "top" }} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Plans</h5>
              <h6 className="card-subtitle text-muted">
                Number of reserved plans
              </h6>
            </div>
            <div className="card-body">
              <div className="chart chart-sm">
                <ResponsiveContainer width="100%" aspect={2}>
                  <BarChart
                    width={500}
                    height={300}
                    data={plans}
                    margin={{
                      top: 15,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" stroke="#000" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="reserva" barSize={25} fill="#E74C3C" label={{ position: "top" }} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Pasadias</h5>
              <h6 className="card-subtitle text-muted">
                Number of reserved pasadias
              </h6>
            </div>
            <div className="card-body">
              <div className="chart chart-sm">
                <ResponsiveContainer width="100%" aspect={2}>
                  <BarChart
                    width={500}
                    height={300}
                    data={pasadias}
                    margin={{
                      top: 15,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" stroke="#000" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="reserva" barSize={25} fill="#3498DB" label={{ position: "top" }} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GraphicsReports;
