import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import FormAddBooking from "../../components/Booking/FormAddBooking";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../features/AuthUser";

const AddBooking = () => {
  const navigate = useNavigate();
  const { token } = AuthUser();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  });

  return (
    <React.Fragment>
      <div className="wrapper">
        <div className="main">
          <Navbar />
          <main className="content" id="mainContent">
            <div className="container-fluid p-0">
            <FormAddBooking />
            </div>
          </main>
          <footer className="footer">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>DisfrutaBuenaventura © 2023</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddBooking;
