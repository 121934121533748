import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const Layout = ({ children }) => {
  const [expanded, setExpand] = useState(false);

  const onExpandChange = () => {
    setExpand(!expanded);
  };

  return (
    <React.Fragment>
      <div className="wrapper">
        <Sidebar style={`sidebar ${expanded === true && 'sidebar collapsed'}`} />
        <div className="main">
          <Navbar onClick={onExpandChange} />
          <main className="content" id="mainContent">
            <div className="container-fluid p-0">{children}</div>
          </main>
          <footer className="footer">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>DisfrutaBuenaventura © 2023</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
