import React, { useEffect } from "react";
import Layout from "../Layout";
import FormDue from "../../components/Due/FormDue";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../features/AuthUser";

const Dues = () => {
  const navigate = useNavigate();
  const { token } = AuthUser();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  });

  return (
    <Layout>
      <FormDue />
    </Layout>
  );
};

export default Dues;
