import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AuthUser from "../../features/AuthUser";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import Container from "react-bootstrap/Container";
import { Print, Download } from "@mui/icons-material";

const Voucher = () => {
  const { http, user } = AuthUser();
  const [code, setCode] = useState("");
  const [checkin, setCheckin] = useState("");
  const [checkout, setCheckout] = useState("");
  const [destiny, setDestiny] = useState("");
  const [type, setType] = useState("");
  const [activity, setActivity] = useState("");
  const [people, setPeople] = useState("");
  const [fullname, setFullname] = useState("");
  const [typeId, setTypeId] = useState("");
  const [numId, setNumId] = useState("");
  const [phone, setPhone] = useState("");
  const [tradename, setTradename] = useState("");

  const [partners, setPartners] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const getBookingId = async () => {
      try {
        const response = await http.get(`/bookings/${id}`);
        setCode(response.data.bookings.code);
        setCheckin(response.data.bookings.checkin);
        setCheckout(response.data.bookings.checkout);
        setDestiny(response.data.bookings.destiny);
        setType(response.data.bookings.type);
        setActivity(response.data.bookings.activity);
        setPeople(response.data.bookings.quantity);
        setFullname(response.data.bookings.fullname);
        setTypeId(response.data.bookings.type_id);
        setNumId(response.data.bookings.dni);
        setPhone(response.data.bookings.phone);
        setTradename(response.data.bookings.tradename);
        
        setPartners(response.data.partners);

        console.log(response.data.bookings);
      } catch (error) {
        if (error.response) {
          console.log(error);
        }
      }
    };
    getBookingId();
  }, []);

  const adults = partners.filter(
    (person) => person.age === 0 || person.age >= 18
  );
  const childs = partners.filter(
    (person) => person.age !== 0 && person.age <= 17
  );

  const containerStyle = {
    width: "800px",
    maxWidth: "100%",
    height: "1200px",
    backgroundColor: "white",
    justifyContent: "space-between",
    position: "relative",
    display: "block",
  };

  const headerStyle = {
    position: "absolute",
    top: "0px",
    right: "0px",
    left: "0px",
    maxWidth: "100%",
  };

  const footerStyle = {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    left: "0px",
    paddingTop: "10px",
    maxWidth: "100%",
  };

  const main = {
    position: "relative",
    paddingTop: "200px",
  };

  const table = {
    border: "1px solid black",
    borderCollapse: "collapse",
    fontFamily: "AvenirNextLTPro",
    fontWeight: "400",
    fontSize: "22px",
    width: "600px",
    margin: "0 auto",
  };

  const th = {
    backgroundColor: "#6da333",
    textAlign: "center",
    border: "1px solid black",
    borderCollapse: "collapse",
    fontFamily: "AvenirNextLTPro",
    fontWeight: "400",
    fontSize: "16px",
  };

  const td = {
    textAlign: "center",
    border: "1px solid black",
    borderCollapse: "collapse",
    fontFamily: "AvenirNextLTPro",
    fontWeight: "400",
    fontSize: "14px",
  };

  function addWaterMark(pdf) {
    var totalPages = pdf.internal.getNumberOfPages();
  
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      //pdf.addImage(imgData, 'PNG', 40, 40, 75, 75);
      pdf.setTextColor(150);
      pdf.text(50, pdf.internal.pageSize.height - 30, 'BORRADOR');
    }
  
    return pdf;
  }

  const createPDF = async () => {
    const pdf = new jsPDF('p', 'mm', [250, 280]);
    const data = await html2canvas(document.querySelector("#pdf"));
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    
    pdf.setFontSize(15);
    pdf.addImage(img, "PNG", 0, 0, 250, 280);
    pdf = addWaterMark(pdf);
    pdf.save(`Voucher-${new Date().toISOString()}.pdf`);
  };

  return (
    <>
      <h1 className="h3 mb-3">Voucher</h1>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="float-end border-radius-lg pt-2 pb-1">
                <button
                  onClick={createPDF}
                  type="button"
                  className="btn btn-info"
                >
                  <Download />
                </button>
              </div>
            </div>
            <div className="card-body">
              <Container style={containerStyle} id="pdf">
                <header style={headerStyle}>
                  <img
                    className="encabezado"
                    src="/header.png"
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </header>
                <div className="contenedor" style={main}>
                  <h3 className="text-center text-dark text-uppercase fs-2 fw-bold">
                    VOUCHER
                  </h3>
                  <h4 className="text-center text-dark text-uppercase fs-4 fw-bold py-3">
                    INFORMACIÓN DEL TITULAR
                  </h4>
                  <table className="table-voucher center" style={table}>
                    <tbody>
                      <tr>
                        <td scope="col" className="fw-bold" style={th}>
                          NOMBRE COMPLETO
                        </td>
                        <td style={td}>
                          <label htmlFor>
                            {fullname}
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold" style={th}>
                          TIPO DE DOCUMENTO
                        </td>
                        <td style={td}>
                          <label htmlFor>
                            {typeId === "CC"
                              ? "Cedula de Ciudadania"
                              : typeId === "CE"
                              ? "Cedula de Extranheria"
                              : "Pasaporte"}
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold" style={th}>
                          No. IDENTIFICACIÓN
                        </td>
                        <td style={td}>
                          <label htmlFor>{numId}</label>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold" style={th}>
                          TELÉFONO
                        </td>
                        <td style={td}>
                          <label htmlFor>{phone}</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <h4 className="text-center text-dark text-uppercase fs-4 fw-bold py-3">
                    INFORMACIÓN DE LA RESERVA
                  </h4>
                  <table className="table-voucher center" style={table}>
                    <tbody>
                      <tr>
                        <td className="fw-bold" style={th}>
                          TIPO DE ACTIVIDAD
                        </td>
                        <td colSpan={2} className="bold" style={td}>
                          <label htmlFor>{type}</label>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold" style={th}>
                          DESTINO
                        </td>
                        <td colSpan={6} style={td}>
                          <label htmlFor>{destiny}</label>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold" style={th}>
                          PLAN/PASADIA
                        </td>
                        <td colSpan={6} style={td}>
                          <label htmlFor>{activity}</label>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold" style={th}>
                          FECHA ENTRADA
                        </td>
                        <td colSpan={6} style={td}>
                          <label htmlFor>
                            {moment(checkin).format("YYYY-MM-DD")}
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold" style={th}>
                          FECHA SALIDA
                        </td>
                        <td colSpan={6} style={td}>
                          <label htmlFor>
                            {moment(checkout).format("YYYY-MM-DD")}
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold" style={th}>
                          ALIMENTACIÓN X PERSONA
                        </td>
                        <td style={td}>
                          <label htmlFor>1</label>
                        </td>
                        <td className="fw-bold" style={th}>
                          <label htmlFor>Desayuno</label>
                        </td>
                        <td style={td}>
                          <label htmlFor>1</label>
                        </td>
                        <td className="fw-bold" style={th}>
                          <label htmlFor>Almuerzo</label>
                        </td>
                        <td style={td}>
                          <label htmlFor>1</label>
                        </td>
                        <td className="fw-bold" style={th}>
                          <label htmlFor>Cena</label>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold" style={th}>
                          SERVICIO ADICIONAL
                        </td>
                        <td colSpan={6} style={td}>
                          <label htmlFor>N/A</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <h4 className="text-center text-dark text-uppercase fs-4 fw-bold py-3">
                    INFORMACIÓN DE LOS ACOMPAÑANTES/PASAJEROS
                  </h4>
                  <table className="table-voucher center" style={table}>
                    <tbody>
                      <tr>
                        <td colSpan={2} className="fw-bold" style={th}>
                          CANTIDAD DE PERSONAS
                        </td>
                        <td style={td}>
                          <label htmlFor>{people}</label>
                        </td>
                        <td className="fw-bold" style={th}>
                          <label htmlFor>Adultos</label>
                        </td>
                        <td style={td}>
                          <label htmlFor>{adults.length + 1}</label>
                        </td>
                        <td className="fw-bold" style={th}>
                          <label htmlFor>Niños</label>
                        </td>
                        <td style={td}>
                          <label htmlFor>{childs.length}</label>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold" style={th} colSpan={7}>
                          DETALLE
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="fw-bold" style={th}>
                          NOMBRE COMPLETO
                        </td>
                        <td colSpan={1} className="fw-bold" style={th}>
                          TYPE ID
                        </td>
                        <td colSpan={3} className="fw-bold" style={th}>
                          No. IDENTIFICACIÓN
                        </td>
                      </tr>
                      {partners.map((partner) => (
                        <tr key={partner.numId}>
                          <td colSpan={3} style={td}>
                            <label htmlFor>
                              {partner.name} {partner.last_name}
                            </label>
                          </td>
                          <td colSpan={1} style={td}>
                            <label htmlFor>{partner.typeId}</label>
                          </td>
                          <td colSpan={3} style={td}>
                            <label htmlFor>{partner.numId}</label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <footer>
                  <img
                    className="footer center"
                    src="/footer.png"
                    alt=""
                    style={footerStyle}
                  />
                </footer>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Voucher;
