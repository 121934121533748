import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthUser from "../../features/AuthUser";
import {
  Pagination,
  Modal,
  Typography,
  Button,
  Box,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import usePagination from "../Utils/Pagination.js";
import { AddCircle, ModeEdit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";

const ProviderList = () => {
  const { http } = AuthUser();
  const [providers, setProviders] = useState([]);

  const [nit, setNit] = useState("");
  const [tradename, setTradename] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [services, setServices] = useState([]);

  const [idProvider, setIdProvider] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchProviders = async () => {
    const response = await http.get("/providers");
    setProviders(response.data);
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(providers.length / PER_PAGE);
  const _DATA = usePagination(providers, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleEdit = async (id) => {
    try {
      const response = await http.get(`/providers/${id}/edit`);
      handleOpen();
      setNit(response.data.nit);
      setName(response.data.name);
      setLastName(response.data.last_name);
      setTradename(response.data.tradename);
      setEmail(response.data.email);
      setPhone(response.data.phone);
      setServices(response.data.services);
      setIdProvider(response.data.uuid);
    } catch (error) {
      handleClose();
      Swal.fire({
        text: "Error, data not found",
        icon: "error",
      });
    }
  };

  const submitUser = async (e) => {
    e.preventDefault();
    try {
      await http
        .put(`/providers/${idProvider}`, {
          nit: nit,
          tradename: tradename,
          name: name,
          last_name: lastName,
          email: email,
          phone: phone
        })
        .then(() => {
          handleClose();
          fetchProviders();
        });
    } catch (error) {
      handleClose();
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        http.delete(`/providers/${id}`);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        fetchProviders();
      }
    });
  };

  const style = {
    position: "absolute",
    top: "60%",
    left: "50%",
    overflow: "scroll",
    height: "100%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <h1 className="h3 mb-3">Provider List</h1>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="float-end border-radius-lg pt-2 pb-1">
                <Link to={"/provider"} className="btn btn-success">
                  <AddCircle />
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive p-0">
                <table className="table table-hover my-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>NIT</th>
                      <th>TradeName</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_DATA.currentData().map((provider, index) => (
                      <tr key={provider.uuid}>
                        <td>{index + 1}</td>
                        <td>{provider.nit}</td>
                        <td>{provider.tradename}</td>
                        <td>{provider.name}</td>
                        <td>{provider.last_name}</td>
                        <td>{provider.email}</td>
                        <td>{provider.phone}</td>
                        <td>
                          <Button
                            onClick={() => handleEdit(provider.uuid)}
                            variant="outlined"
                            color="success"
                          >
                            <ModeEdit />
                          </Button>
                          <Button
                            onClick={() => deleteUser(provider.uuid)}
                            variant="outlined"
                            color="error"
                          >
                            <Delete />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mx-auto py-2" style={{ width: "200px" }}>
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Provider Update
          </Typography>
          <Form onSubmit={submitUser}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>NIT</Form.Label>
              <Form.Control type="number" value={nit} onChange={(e) => setNit(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Tradename</Form.Label>
              <Form.Control type="text" value={tradename} onChange={(e) => setTradename(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Phone</Form.Label>
              <Form.Control type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </Form.Group>

            <Button variant="outlined" color="primary" type="submit" block className="mb-4">
              Update
            </Button>
          </Form>
        </Box>
      </Modal>
    </>
  );
};

export default ProviderList;
