import React, { useEffect } from 'react';
import Layout from '../Layout';
import Voucher from '../../components/Booking/Voucher';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../features/AuthUser';


const VoucherPDF = () => {
    const navigate = useNavigate();
    const { token } = AuthUser();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    })

    return (
        <Layout>
            <Voucher />
        </Layout>
    )
}

export default VoucherPDF