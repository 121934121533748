import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import AuthUser from "../../features/AuthUser";
import { Button, Pagination, Stack } from "@mui/material";
import usePagination from "../Utils/Pagination.js";
import {
  AddCircle,
  ModeEdit,
  AttachEmail,
  PictureAsPdf,
} from "@mui/icons-material";
import Swal from "sweetalert2";

const BookingList = () => {
  const { http } = AuthUser();
  const [bookings, setBookings] = useState([]);

  const getBookings = async () => {
    const response = await http.get("/bookings");
    setBookings(response.data);
  };

  useEffect(() => {
    getBookings();
  }, []);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(bookings.length / PER_PAGE);
  const _DATA = usePagination(bookings, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const voucher = async (id) => {
    try {
      await http.get(`/voucher/${id}`);
      Swal.fire({
        text: "PDF creado exitosamente",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  const voucherMail = async (id) => {
    try {
      const response = await http.get(`/voucher-send/${id}`);
      if (response) {
        Swal.fire({
          text: "Email sent",
          icon: "success",
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  const openInNewTab = (id) => {
    let url = http.get(`/voucher/${id}`);
    window.open(url, "_blank", "noreferrer");
  };

  /* const openInNewTab = (id) => {
    http.get(`/voucher/${id}`);
  }; */

  return (
    <>
      <h1 className="h3 mb-3">Bookings List</h1>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="float-end border-radius-lg pt-2 pb-1">
                <Link to={"/booking"} className="btn btn-success">
                  <AddCircle />
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive p-0">
                <table className="table table-hover my-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Code</th>
                      <th>Client</th>
                      <th>Checkin</th>
                      <th>Checkout</th>
                      <th>Destiny</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Actions</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_DATA.currentData().map((booking, index) => (
                      <tr key={booking.uuid}>
                        <td>{index + 1}</td>
                        <td>
                          <Link
                            to={`/voucher/${booking.uuid}`}
                            className="btn btn-outline-secondary"
                          >
                            {booking.code}
                          </Link>
                        </td>
                        <td>{booking.fullname}</td>
                        <td>{moment(booking.checkin).format("YYYY-MM-DD")}</td>
                        <td>{moment(booking.checkout).format("YYYY-MM-DD")}</td>
                        <td>{booking.destiny}</td>
                        <td>{booking.total}</td>
                        <td>
                          {booking.status === "Pending" ? (
                            <span className="badge bg-warning">Pending</span>
                          ) : booking.status === "Accepted" ? (
                            <span className="badge bg-info">Accepted</span>
                          ) : booking.status === "Completed" ? (
                            <span className="badge bg-success">Comlpeted</span>
                          ) : (
                            <span class="badge bg-danger">Cancelled</span>
                          )}
                        </td>
                        <td>
                          <Button
                            component={Link}
                            to={`/bookings/${booking.uuid}/edit`}
                            variant="outlined"
                            color="success"
                          >
                            <ModeEdit />
                          </Button>
                          <Button
                            onClick={() => voucherMail(`${booking.uuid}`)}
                            variant="outlined"
                            color="secondary"
                          >
                            <AttachEmail />
                          </Button>
                        </td>
                        <td>
                          <Button
                            onClick={() => voucher(`${booking.uuid}`)}
                            variant="outlined"
                            color="error"
                          >
                            CrearPDF
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mx-auto py-2" style={{ width: "200px" }}>
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingList;
