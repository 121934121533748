import React, { useEffect } from "react";
import Layout from "../Layout";
import CompareDateReport from "../../components/Report/CompareDateReport";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../features/AuthUser";

const ReportDateCompare = () => {
    const navigate = useNavigate();
  const { token } = AuthUser();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  });

  return (
    <Layout>
      <CompareDateReport />
    </Layout>
  )
}

export default ReportDateCompare
