import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../features/AuthUser";

const BookingCalendar = () => {
  const { http, user } = AuthUser();
  const id = user.uuid;
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([
    {
      id: "",
      title: "",
      start: "",
      end: "",
      color: "",
    },
  ]);

  const [calendarUser, setCalendarUser] = useState([
    {
      id: "",
      title: "",
      start: "",
      end: "",
      color: "",
    },
  ]);

  useEffect(() => {
    getBookings();
    getCalendarUser();
  }, []);

  const getBookings = async () => {
    const response = await http.get("/calendar");

    setBookings(
      response.data.map((option) => ({
        id: option.uuid,
        title: option.code,
        start: option.checkin,
        end: option.checkout,
        color: [
          option.status === "Pending"
            ? "#ff8c00"
            : option.status === "Accepted"
            ? "#0000ff"
            : option.status === "Completed"
            ? "#228b22"
            : "#ff0000",
        ],
      }))
    );
  };

  const getCalendarUser = async () => {
    const response = await http.get(`/calendar-user/${id}`);

    setCalendarUser(
      response.data.map((option) => ({
        id: option.uuid,
        title: option.code,
        start: option.checkin,
        end: option.checkout,
        color: [
          option.status === "Pending"
            ? "#ff8c00"
            : option.status === "Accepted"
            ? "#0000ff"
            : option.status === "Completed"
            ? "#228b22"
            : "#ff0000",
        ],
      }))
    );
  };

  const handleBookClick = async (bookId) => {
    navigate(`/voucher/${bookId}`);
  };

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 col-lg-12">
        <h2 className="subtitle">Reservation Calendar</h2>
        {user && user.role === "admin" && (
          <div className="offset-1 bg-white">
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              selectable
              events={bookings}
              timeZone="America/Bogota"
              locale="es"
              eventColor={bookings.color}
              eventClick={function (info) {
                info.jsEvent.preventDefault();

                if (info.event.id) {
                  handleBookClick(info.event.id);
                }
              }}
            />
          </div>
        )}

        {user && user.role === "user" && (
          <div className="offset-1 bg-white">
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              selectable
              events={calendarUser}
              timeZone="America/Bogota"
              locale="es"
              eventColor={calendarUser.color}
              eventClick={function (info) {
                info.jsEvent.preventDefault();

                if (info.event.id) {
                  handleBookClick(info.event.id);
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingCalendar;
