import React, { useEffect } from 'react'
import Layout from '../Layout';
import FormTypeCrud from '../../components/Type/FormTypeCrud';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../features/AuthUser';

const Types = () => {
    const navigate = useNavigate();
    const { token } = AuthUser();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    })

    return (
        <div>
            <Layout>
                <FormTypeCrud />
            </Layout>
        </div >
    )
}

export default Types
