import React, { useEffect } from 'react';
import Layout from '../Layout';
import BookingCalendar from '../../components/Booking/BookingCalendar';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../features/AuthUser';

const Calendar = () => {
    const navigate = useNavigate();
    const { token } = AuthUser();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    })

    return (
        <div>
            <Layout>
                <BookingCalendar />
            </Layout>
        </div>
    )
}

export default Calendar
