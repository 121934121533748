import React, { useEffect } from 'react';
import Layout from '../Layout';
import ClientList from '../../components/Client/ClientList';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../features/AuthUser';

const Clients = () => {
  const navigate = useNavigate();
  const { token } = AuthUser();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  })

  return (
    <div>
      <Layout>
        <ClientList />
      </Layout>
    </div>
  )
}

export default Clients
