import React, {useEffect} from 'react';
import Layout from '../Layout';
import BookingList from '../../components/Booking/BookingList';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../features/AuthUser';

const Bookings = () => {
    const navigate = useNavigate();
    const { token } = AuthUser();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    })

    return (
        <div>
            <Layout>
                <BookingList />
            </Layout>
        </div>
    )
}

export default Bookings