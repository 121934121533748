import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthUser from "../../features/AuthUser";
import { Pagination, Modal, Typography, Button, Box } from "@mui/material";
import usePagination from "../Utils/Pagination.js";
import { AddCircle, ModeEdit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";

const UsersList = () => {
  const { http } = AuthUser();
  const [users, setUsers] = useState([]);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");
  const [role, setRole] = useState("");

  const [idUser, setIdUser] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchUsers = async () => {
    const response = await http.get("/users");
    setUsers(response.data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(users.length / PER_PAGE);
  const _DATA = usePagination(users, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleEdit = async (id) => {
    try {
      const response = await http.get(`/users/${id}/edit`);
      handleOpen();
      setName(response.data.name);
      setLastName(response.data.last_name);
      setEmail(response.data.email);
      setPhone(response.data.phone);
      setStatus(response.data.status);
      setRole(response.data.role);
      setIdUser(response.data.uuid);
    } catch (error) {
      handleClose();
      Swal.fire({
        text: "Error, data not found",
        icon: "error",
      });
      console.log(error);
    }
  };

  const submitUser = async (e) => {
    e.preventDefault();
    try {
      await http
        .put(`/users/${idUser}`, {
          status: status,
          role: role,
        })
        .then(() => {
          handleClose();
          fetchUsers();
        });
    } catch (error) {
      handleClose();
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
      console.log(error);
    }
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        http.delete(`/users/${id}`);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        fetchUsers();
      }
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <h1 className="h3 mb-3">Users List</h1>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="float-end border-radius-lg pt-2 pb-1">
                <Link to={"/user"} className="btn btn-success">
                  <AddCircle />
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive p-0">
                <table className="table table-hover my-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Number Phone</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_DATA.currentData().map((user, index) => (
                      <tr key={user.uuid}>
                        <td>{index + 1}</td>
                        <td>{user.name}</td>
                        <td>{user.last_name}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                        <td>{user.phone}</td>
                        <td>
                          {user.status === 1 ? (
                            <span className="badge bg-success">Active</span>
                          ) : (
                            <span class="badge bg-danger">inActive</span>
                          )}
                        </td>
                        <td>
                          <Button
                            onClick={() => handleEdit(user.uuid)}
                            variant="outlined"
                            color="success"
                          >
                            <ModeEdit />
                          </Button>
                          <Button
                            onClick={() => deleteUser(user.uuid)}
                            variant="outlined"
                            color="error"
                          >
                            <Delete />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mx-auto py-2" style={{ width: "200px" }}>
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            User Update
          </Typography>
          <Form onSubmit={submitUser}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" value={name} readOnly />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" value={lastName} readOnly />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={email} readOnly />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Phone</Form.Label>
              <Form.Control type="text" value={phone} readOnly />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Role</Form.Label>
              <Form.Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                aria-label="Default select example"
              >
                <option value="admin">Admin</option>
                <option value="operator">Operator</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Status</Form.Label>
              <Form.Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                aria-label="Default select example"
              >
                <option value="1">Active</option>
                <option value="2">inActive</option>
              </Form.Select>
            </Form.Group>
            <Button variant="outlined" color="primary" type="submit" block>
              Update
            </Button>
          </Form>
        </Box>
      </Modal>
    </>
  );
};

export default UsersList;
