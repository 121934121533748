import React, { useEffect } from "react";
import Layout from "../Layout";
import UsersList from "../../components/User/UsersList";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../features/AuthUser";

const Users = () => {
  const navigate = useNavigate();
  const { token } = AuthUser();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  });

  return (
    <Layout>
      <UsersList />
    </Layout>
  );
};

export default Users;
