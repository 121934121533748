import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment";
import AuthUser from "../../features/AuthUser";
import {
  Button,
  Pagination,
  Modal,
  Typography,
  Box,
} from "@mui/material";
import { Form } from "react-bootstrap";
import usePagination from "../Utils/Pagination.js";
import { PublishedWithChanges, Download } from "@mui/icons-material";
import Swal from "sweetalert2";

const FormDue = () => {
  const { http } = AuthUser();
  const tableRef = useRef(null);

  const [bookings, setBookings] = useState([]);

  const [id, setId] = useState();
  const [amount, setAmount] = useState([]);
  const [status, setStatus] = useState([]);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const getBookings = async () => {
    const response = await http.get("/dues");
    setBookings(response.data);
  };

  useEffect(() => {
    getBookings();
  }, []);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(bookings.length / PER_PAGE);
  const _DATA = usePagination(bookings, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleOnExport = () => {
    var table_elt = document.getElementById("dues-table-id");

    var workbook = XLSX.utils.table_to_book(table_elt);

    var ws = workbook.Sheets["Operativo"];
    XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], {
      origin: -1,
    });

    XLSX.writeFile(workbook, "DueBookingsDB.xlsx");
  };

  const handleShowEdit = async (id) => {
    try {
      const response = await http.get(`/dues/${id}/edit`);
      handleOpenEdit();
      setAmount(response.data.amount);
      setStatus(response.data.status);
      setId(response.data.uuid);
    } catch (error) {
      handleCloseEdit();
      Swal.fire({
        text: "Error, data not found",
        icon: "error",
      });
      console.log(error);
    }
  };

  const updateDue = async (e) => {
    e.preventDefault();
    try {
      await http
        .put(`/dues/${id}`, {
          amount: amount,
          status: status,
        })
        .then(() => {
          handleCloseEdit();
          setAmount();
          getBookings();
        });
    } catch (error) {
      Swal.fire({
        text: "Error, data cannot be updated",
        icon: "error",
      });
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <h1 className="h3 mb-3">Operativo</h1>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="float-end border-radius-lg pt-2 pb-1">
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleOnExport}
                >
                  <Download />
                </Button>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive p-0">
                <table
                  className="table table-hover my-0"
                  ref={tableRef}
                  id="dues-table-id"
                >
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Code</th>
                      <th>Client</th>
                      <th>Checkin</th>
                      <th>Checkout</th>
                      <th>Total</th>
                      <th>Abono</th>
                      <th>Debe</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_DATA.currentData().map((due, index) => (
                      <tr key={due.uuid}>
                        <td>{index + 1}</td>
                        <td>{due.code}</td>
                        <td>{due.fullname}</td>
                        <td>{moment(due.checkin).format("YYYY-MM-DD")}</td>
                        <td>{moment(due.checkout).format("YYYY-MM-DD")}</td>
                        <td>{due.total}</td>
                        <td>{due.amount}</td>
                        <td>{due.total - due.amount}</td>
                        <td>
                          {due.estado === "Pending" ? (
                            <span className="badge bg-warning">Pending</span>
                          ) : due.estado === "Completed" ? (
                            <span className="badge bg-success">Comlpeted</span>
                          ) : (
                            <span class="badge bg-danger">Cancelled</span>
                          )}
                        </td>
                        <td>
                          {due.total - due.amount === 0 ? (
                            <Button
                              variant="outlined"
                              color="secondary"
                              disabled
                            >
                              <PublishedWithChanges />
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => handleShowEdit(`${due.dueid}`)}
                            >
                              <PublishedWithChanges />
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mx-auto py-2" style={{ width: "200px" }}>
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Abono
          </Typography>
          <Form onSubmit={updateDue}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Dinero abonar"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="statusSelect">
              <Form.Label>Status</Form.Label>
              <Form.Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                aria-label="Default select example"
              >
                <option value="Pending">Pending</option>
                <option value="Completed">Comlpeted</option>
                <option value="Cancelled">Cancelled</option>
              </Form.Select>
            </Form.Group>
            <Button variant="outlined" color="primary" type="submit" block>
              Update
            </Button>
          </Form>
        </Box>
      </Modal>
    </>
  );
};

export default FormDue;
