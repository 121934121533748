import React, { useEffect } from 'react';
import Layout from '../Layout';
import GraphicsReports from '../../components/Report/GraphicsReports';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../features/AuthUser';

const Graphics = () => {
    const navigate = useNavigate();
    const { token } = AuthUser();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    })

    return (
        <>
            <Layout>
                <GraphicsReports />
            </Layout>
        </>
    )
}

export default Graphics
