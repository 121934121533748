import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import MUIDataTable from "mui-datatables";
import { Button, Pagination, Stack } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Swal from "sweetalert2";
import AuthUser from "../../features/AuthUser";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";

const DateRangeReport = () => {
  const { http, user } = AuthUser();
  dayjs.extend(customParseFormat);
  const dateFormat = "YYYY-MM-DD";

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [bookings, setBookings] = useState([]);
  const [destiny, setDestiny] = useState([]);
  const [people, setPeople] = useState([]);
  const [daysMonth, setdaysMonth] = useState([]);
  const [plans, setPlans] = useState([]);
  const [pasadias, setPasadias] = useState([]);

  const columns = [
    {
      name: "code",
      label: "Code",
    },
    {
      name: "fullname",
      label: "FullName",
    },
    {
      name: "checkin",
      label: "Check-In",
    },
    {
      name: "checkout",
      label: "Check-Out",
    },
    {
      name: "destiny",
      label: "Destiny",
    },
    {
      name: "activity",
      label: "Activity",
    },
    {
      name: "total",
      label: "Total",
    }
  ];

  const onChangeStart = (date, dateString) => {
    setStartDate(dateString);
  };

  const onChangeEnd = (date, dateString) => {
    setEndDate(dateString);
  };

  /*  useEffect(()=>{
    console.log(startDate)
    console.log(endDate)
  });
 */
  const fetchData = async () => {
    try {
      const response = await http.get(`/dateRange/${startDate}/${endDate}`);
      //const response = await http.get("/dateRange/2023-01-01/2023-01-25");
      setBookings(response.data.bookings);
      setPeople(response.data.people);
      setDestiny(response.data.reservas);
      setdaysMonth(response.data.daysMonth);
      setPlans(response.data.plans);
      setPasadias(response.data.pasadias);
      /* console.log(response.data.bookings);
      console.log(response.data.people);
      console.log(response.data.reservas); */
    } catch (error) {
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Buscar por un rango de fecha</h5>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-center">
                <div className="p-2">
                  <DatePicker
                    format={dateFormat}
                    onChange={onChangeStart}
                    className="form-control"
                  />
                </div>
                <div className="p-2">
                  <DatePicker
                    format={dateFormat}
                    onChange={onChangeEnd}
                    className="form-control"
                  />
                </div>
                <div className="p-2">
                  <Button
                    onClick={() => fetchData()}
                    variant="contained"
                    color="success"
                    endIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <MUIDataTable
                title={"Total de reservas"}
                data={bookings}
                columns={columns}
              />
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title">Total de viajeros por Destino</h5>
            </div>
            <div className="card-body">
              <div className="chart">
                <ResponsiveContainer width="100%" aspect={2}>
                  <BarChart
                    width={800}
                    height={300}
                    data={people}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" stroke="#000" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="people" barSize={15} fill="#413ea0" label={{ position: "top" }} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-xl-6 col-xxl-6">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title">Total de planes reservados por destino</h5>
            </div>
            <div className="card-body">
              <div className="chart">
                <ResponsiveContainer width="100%" height="100%" aspect={2}>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={plans}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 50,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" barSize={20} fill="#413ea0" />
                    <Line dataKey="total" stroke="#ff7300" />
                  </ComposedChart>
                </ResponsiveContainer>

              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-xl-6 col-xxl-6">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title">Total de pasadias reservadas por destino</h5>
            </div>
            <div className="card-body">
              <div className="chart">
                <ResponsiveContainer width="100%" height="100%" aspect={2}>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={pasadias}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 50,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" barSize={20} fill="#413ea0" />
                    <Line dataKey="total" stroke="#ff7300" />
                  </ComposedChart>
                </ResponsiveContainer>

              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title">Total de Reservas por Destino</h5>
            </div>
            <div className="card-body">
              <div className="chart">
                <ResponsiveContainer width="100%" height="100%" aspect={2}>
                  <ComposedChart
                    layout="vertical"
                    width={500}
                    height={400}
                    data={destiny}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 50,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" scale="band" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="reservas" barSize={20} fill="#413ea0" />
                    <Line dataKey="reservas" stroke="#ff7300" />
                  </ComposedChart>
                </ResponsiveContainer>

              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title">Cantidad de Viajeros por dias</h5>
            </div>
            <div className="card-body">
              <div className="chart">
              <ResponsiveContainer width="100%" height="100%" aspect={2}>
                  <LineChart
                    width={500}
                    height={300}
                    data={daysMonth}
                    margin={{
                      top: 5,
                      right: 5,
                      left: 5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="dia" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="visitantes"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>

              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default DateRangeReport;
