import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthUser from "../../features/AuthUser";
import { Delete, ModeEdit, AddCircle } from "@mui/icons-material";
import { Pagination, Stack } from "@mui/material";
import usePagination from "../Utils/Pagination.js";

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const { http } = AuthUser();

  const [page, setPage] = useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(clients.length / PER_PAGE);
  const _DATA = usePagination(clients, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    const response = await http.get("/clients");
    setClients(response.data);
  };

  const deleteClient = async (clientId) => {
    await http.get(`/clients/${clientId}`);
    getClients();
  };

  return (
    <>
      <h1 className="h3 mb-3">
        <strong>Clients</strong>
      </h1>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="float-end border-radius-lg pt-2 pb-1">
                <Link to={"/"} className="btn btn-success">
                  <AddCircle />
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive p-0">
                <table className="table table-hover my-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Type ID</th>
                      <th>DNI</th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Country</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_DATA.currentData().map((client, index) => (
                      <tr key={client.uuid}>
                        <td>{index + 1}</td>
                        <td>
                          {client.type_id === "CC"
                            ? "Cedula de Ciudadania"
                            : client.type_id === "CE"
                            ? "Cedula de Extranjeria"
                            : client.type_id === "PP"
                            ? "Pasaporte"
                            : null}
                        </td>
                        <td>
                          {client.dni}
                        </td>
                        <td>
                          {client.fullname}
                        </td>
                        <td>
                          {client.email}
                        </td>
                        <td>
                          {client.Country}
                        </td>
                        <td>
                          <Link
                            to={`/clients/edit/${client.uuid}`}
                            className="btn btn-outline-success"
                          >
                            <ModeEdit />
                          </Link>
                          <button
                            onClick={() => deleteClient(client.uuid)}
                            className="btn btn-outline-danger"
                          >
                            <Delete />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mx-auto" style={{ width: "200px" }}>
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientList;
