import React, { useEffect } from "react";
import Layout from "../Layout";
import FormEditClient from "../../components/Client/FormEditClient";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../features/AuthUser";

const EditClient = () => {
  const navigate = useNavigate();
  const { token } = AuthUser();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  });
  return (
    <Layout>
      <FormEditClient />
    </Layout>
  );
};

export default EditClient;
