import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AuthUser from "../features/AuthUser";

const ChangePassword = () => {
  const { http, setToken } = AuthUser();
  const navigate = useNavigate();
  const { token } = useParams();

  const [meter, setMeter] = useState(false);

  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g;
  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*+_-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{8,}/g; // eight characters or more

  const passwordTracker = {
    uppercase: newPassword.match(atLeastOneUppercase),
    lowercase: newPassword.match(atLeastOneLowercase),
    number: newPassword.match(atLeastOneNumeric),
    specialChar: newPassword.match(atLeastOneSpecialChar),
    eightCharsOrGreater: newPassword.match(eightCharsOrMore),
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  const handleValidation = () => {
    if (confirmPassword || (newPassword && confirmPassword.length > 0)) {
      if (confirmPassword !== newPassword) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    try {
      const response = await http.post("/reset-password", {
        email: email,
        token: token,
        password: newPassword,
        password_confirmation: confirmPassword,
      });

      if (response.status === 200) {
        Swal.fire({
          text: response.success,
          icon: "success",
        }).then((res) => {
          if (res) {
            navigate("/login");
          }
        });
      }

    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        Swal.fire({
          text: error.response.error,
          icon: "error",
        });
      } else {
        Swal.fire({
          text: "Error, data cannot be recorded",
          icon: "error",
        });
      }
    }
  };

  return (
    <>
      <main
        className="d-flex w-100"
        style={{
          backgroundImage: `url("/img/playa-pianguita-buenaventura.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="container d-flex flex-column">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      <div className="text-center">
                        <img
                          src="/brand/logo-disfrutabun.svg"
                          alt="Logo"
                          className="img-fluid"
                          width={132}
                          height={132}
                        />
                      </div>
                      <form onSubmit={changePassword}>
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <input
                            className="form-control form-control-lg"
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">New Password</label>
                          <input
                            className="form-control form-control-lg"
                            type="password"
                            id="new_password"
                            name="new_password"
                            value={newPassword}
                            onFocus={() => setMeter(true)}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                          {meter && (
                            <div>
                              <div className="password-strength-meter"></div>
                              <div>
                                {passwordStrength < 5 && "Must contain "}
                                {!passwordTracker.uppercase && "uppercase, "}
                                {!passwordTracker.lowercase && "lowercase, "}
                                {!passwordTracker.specialChar &&
                                  "special character, "}
                                {!passwordTracker.number && "number, "}
                                {!passwordTracker.eightCharsOrGreater &&
                                  "eight characters or more"}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="mb-3">
                          <label className="form-label">
                            Confirm New Password
                          </label>
                          <input
                            className="form-control form-control-lg"
                            type="password"
                            id="new_password_confirmation"
                            name="new_password_confirmation"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onKeyUp={handleValidation}
                          />
                          <p className="text-danger">{confirmPasswordError}</p>
                        </div>

                        <div className="text-center mt-3">
                          <button
                            className="btn btn-lg btn-success"
                            type="submit"
                          >
                            Reset Password
                          </button>
                        </div>
                      </form>
                      <style jsx>
                        {`
                          .password-strength-meter {
                            height: 0.3rem;
                            background-color: lightgrey;
                            border-radius: 3px;
                            margin: 0.5rem 0;
                          }

                          .password-strength-meter::before {
                            content: "";
                            background-color: ${[
                              "red",
                              "orange",
                              "#03a2cc",
                              "#03a2cc",
                              "#0ce052",
                            ][passwordStrength - 1] || ""};
                            height: 100%;
                            width: ${(passwordStrength / 5) * 100}%;
                            display: block;
                            border-radius: 3px;
                            transition: width 0.2s;
                          }
                        `}
                      </style>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ChangePassword;
