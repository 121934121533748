import React, { useState, useEffect } from "react";
import AuthUser from "../features/AuthUser";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Link } from "react-router-dom";
import {
  GridView,
  CalendarMonth,
  Close,
  Event,
  Group,
  People,
  Analytics,
  DateRange,
} from "@mui/icons-material";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";

function Welcome() {
  const { http } = AuthUser();
  const [totalBookings, setTotalBookings] = useState();
  const [totalClients, setTotalClients] = useState();
  const [totalPartners, setTotalPartners] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [currentMonth, setCurrentMonth] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [bookStatus, setBookStatus] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getTotalBookings = async () => {
      const response = await http.get("/totalBookings");
      setTotalBookings(response.data.total);
    };
    getTotalBookings();
  }, []);

  useEffect(() => {
    const getTotalClients = async () => {
      const response = await http.get("/totalClients");
      setTotalClients(response.data.total);
    };
    getTotalClients();
  }, []);

  useEffect(() => {
    const getTotalPartners = async () => {
      const response = await http.get("/totalPartners");
      setTotalPartners(response.data.total);
    };
    getTotalPartners();
  }, []);

  useEffect(() => {
    const getTotalUsers = async () => {
      const response = await http.get("/totalUsers");
      setTotalUsers(response.data.total);
    };
    getTotalUsers();
  }, []);

  useEffect(() => {
    const getCurrentMonth = async () => {
      await http.get("/currentMonth").then((response) => {
        setCurrentMonth(response.data);
      });
    };
    getCurrentMonth();
  }, []);

  useEffect(() => {
    const getBookings = async () => {
      const response = await http.get("/bookingsLatest");
      setBookings(response.data);
    };
    getBookings();
  }, []);

  useEffect(() => {
    const getBookStatus = async () => {
      await http.get("/bookStatus").then((response) => {
        setBookStatus(response.data);
      });
    };
    getBookStatus();
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      await http.get("/chart-countries").then((response) => {
        setCountries(response.data);
      });
    };
    getCountries();
  }, []);

  const COLORS = ["#42DC39", "#FFBB28", "#0088FE", "#FF3240"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


  return (
    <>
      <h1 className="h3 mb-3">
        <strong>Dashboard</strong>
      </h1>
      <div className="row">
        <div className="col-xl-6 col-xxl-5 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-sm-6">
                <Link to={"/bookingList"} className="cursor-pointer">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col mt-0">
                          <h5 className="card-title">Bookings</h5>
                        </div>
                        <div className="col-auto">
                          <div className="stat text-primary">
                            <DateRange className="align-middle" />
                          </div>
                        </div>
                      </div>
                      <h1 className="mt-1 mb-3">{totalBookings}</h1>
                    </div>
                  </div>
                </Link>

                <Link to={"/reports-destiny"} className="cursor-pointer">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col mt-0">
                          <h5 className="card-title">Visitors</h5>
                        </div>
                        <div className="col-auto">
                          <div className="stat text-primary">
                            <Group className="align-middle" />
                          </div>
                        </div>
                      </div>
                      <h1 className="mt-1 mb-3">
                        {totalPartners}
                      </h1>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6">
                <Link to={"/clients"} className="cursor-pointer">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col mt-0">
                          <h5 className="card-title">Clients</h5>
                        </div>
                        <div className="col-auto">
                          <div className="stat text-primary">
                            <People className="align-middle" />
                          </div>
                        </div>
                      </div>
                      <h1 className="mt-1 mb-3">{totalClients}</h1>
                    </div>
                  </div>
                </Link>

                <Link to={"/users"} className="cursor-pointer">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col mt-0">
                          <h5 className="card-title">Users</h5>
                        </div>
                        <div className="col-auto">
                          <div className="stat text-primary">
                            <Group className="align-middle" />
                          </div>
                        </div>
                      </div>
                      <h1 className="mt-1 mb-3">{totalUsers}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title mb-0">Reservations this month</h5>
            </div>
            <div className="card-body py-3">
              <div className="chart chart-sm">
                <ResponsiveContainer width="100%" height="100%" aspect={2}>
                  <LineChart
                    width={500}
                    height={400}
                    data={currentMonth}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="fecha" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="people"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-6 col-xxl-6 d-flex order-2 order-xxl-2">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title mb-0">Bookings</h5>
            </div>
            <div className="card-body d-flex">
              <div className="align-self-center w-100">
                <div className="py-3">
                  <div className="chart chart-sm">
                    <ResponsiveContainer width="100%" height="100%" aspect={2}>
                      <PieChart width={200} height={200}>
                        <Pie
                          data={bookStatus}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={100}
                          fill="#8884d8"
                          dataKey="reservas"
                        >
                          {bookStatus.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <table className="table mb-0">
                  {bookStatus.map((booking) => (
                    <tbody>
                      {booking.name === "Pending" ? (
                        <tr>
                          <td>
                            <span className="badge bg-warning">Pending</span>
                          </td>
                          <td className="text-end">{booking.reservas}</td>
                        </tr>
                      ) : booking.name === "Accepted" ? (
                        <tr>
                          <td>
                            <span className="badge bg-info">Accepted</span>
                          </td>
                          <td className="text-end">{booking.reservas}</td>
                        </tr>
                      ) : booking.name === "Completed" ? (
                        <tr>
                          <td>
                            <span className="badge bg-success">Comlpeted</span>
                          </td>
                          <td className="text-end">{booking.reservas}</td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            <span class="badge bg-danger">Cancelled</span>
                          </td>
                          <td className="text-end">{booking.reservas}</td>
                        </tr>
                      )}
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-xxl-6 d-flex order-1 order-xxl-1">
          <div className="card flex-fill">
            <div className="card-header">
              <h5 className="card-title mb-0">Calendar</h5>
            </div>
            <div className="card-body d-flex">
              <div className="align-self-center w-100">
                <div className="chart">
                  <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    timeZone="America/Bogota"
                    locale="es"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-12 col-xxl-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <h5 className="card-title mb-0">Last Reservations</h5>
            </div>
            <table className="table table-hover my-0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Destiny</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {bookings.map((booking, index) => (
                  <tr key={index}>
                    <td>
                      {booking.fullname}
                    </td>
                    <td className="d-none d-xl-table-cell">
                      {moment(booking.checkin).format("YYYY-MM-DD")}
                    </td>
                    <td className="d-none d-xl-table-cell">
                      {moment(booking.checkout).format("YYYY-MM-DD")}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {booking.destiny}
                    </td>
                    <td>
                      {booking.status === "Pending" ? (
                        <span className="badge bg-warning">Pending</span>
                      ) : booking.status === "Accepted" ? (
                        <span className="badge bg-info">Accepted</span>
                      ) : booking.status === "Completed" ? (
                        <span className="badge bg-success">Comlpeted</span>
                      ) : (
                        <span className="badge bg-danger">Cancelled</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;
