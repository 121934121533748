import React from "react";
import FormAddUserClient from "../../components/Client/FormAddUserClient";

const AddUserClient = () => {
  return (
    <>
      <FormAddUserClient />
    </>
  );
};

export default AddUserClient;
