import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import ChangePassword from "./components/ChangePassword";
import Dashboard from "./pages/Dashboard";
import Types from "./pages/Type/Types";
import Destinations from "./pages/Destiny/Destinations";
import Activities from "./pages/Activity/Activities";
import Clients from "./pages/Client/Clients";
import AddUserClient from "./pages/Client/AddUserClient";
import EditClient from "./pages/Client/EditClient";
import AddBooking from "./pages/Booking/AddBooking";
import AddUser from "./pages/User/AddUser";
import Users from "./pages/User/Users";
import Profile from "./pages/User/Profile";
import AddProvider from "./pages/Provider/AddProvider";
import Providers from "./pages/Provider/Providers";
import Bookings from "./pages/Booking/Bookings";
import BookingsByUser from "./pages/Booking/BookingsByUser";
import BookingCalendar from "./pages/Booking/Calendar";
import Due from "./pages/Due/Dues";
import VoucherPDF from "./pages/Booking/VoucherPDF";
import EditBooking from "./pages/Booking/EditBooking";
import Monthly from "./pages/Report/Monthly";
import Graphics from "./pages/Report/Graphics";
import Destiny from "./pages/Report/Destiny";
import ReportDateRange from "./pages/Report/ReportDateRange";
import ReportDateCompare from "./pages/Report/ReportDateCompare";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AddUserClient />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ResetPassword />} />
        <Route path="/change-password/:token" element={<ChangePassword />} />
        <Route path="/types" element={<Types />} />
        <Route path="/destinations" element={<Destinations />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/clients/edit" element={<EditClient />} />
        <Route path="/booking" element={<AddBooking />} />
        <Route path="/users" element={<Users />} />
        <Route path="/user" element={<AddUser />} />
        <Route path="/providers" element={<Providers />} />
        <Route path="/provider" element={<AddProvider />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/bookings" element={<Bookings />} />
        <Route path="/bookings-by-user" element={<BookingsByUser />} />
        <Route path="/calendar" element={<BookingCalendar />} />
        <Route path="/dues" element={<Due />} />
        <Route path="/voucher/:id" element={<VoucherPDF />} />
        <Route path="/bookings/:id/edit" element={<EditBooking />} />
        <Route path="/monthly" element={<Monthly />} />
        <Route path="/graphics" element={<Graphics />} />
        <Route path="/reports-destiny" element={<Destiny />} />
        <Route path="/reports-date-range" element={<ReportDateRange />} />
        <Route path="/reports-date-compare" element={<ReportDateCompare />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
