import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthUser from "../../features/AuthUser";
import {
  Box,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from "@mui/material";

const FormAddProvider = () => {
  const { http } = AuthUser();
  const navigate = useNavigate();

  const [nit, setNit] = useState("");
  const [tradename, setTradename] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [providerInfo, setProviderInfo] = useState({
    services: [],
  });

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const { services } = providerInfo;
    
    if (checked) {
      setProviderInfo({
        services: [...services, value],
      });
    }else {
      setProviderInfo({
        services: services.filter((e) => e !== value),
      });
    }
  };

  const saveProvider = async (e) => {
    e.preventDefault();
    try {
      const response = await http.post("/providers", {
        nit: nit,
        name: name,
        last_name: lastName,
        tradename: tradename,
        email: email,
        phone: phone,
        services: providerInfo.services
      });

      if (response) {
        navigate("/providers");
      }
    } catch (error) {
      console.log(error)
      if (error.response.status === 402) {
        Swal.fire({
          text: "Provider is already registered",
          icon: "error",
        });
      } else {
        Swal.fire({
          text: "Error, data cannot be recorded",
          icon: "error",
        });
      }
    }
  };

  return (
    <>
      <h1 className="h3 mb-3">Add Provider</h1>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form
                onSubmit={saveProvider}
                className="row g-3 justify-content-md-center"
              >
                <div className="col-md-6">
                  <label className="form-label">NIT</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Nit"
                      name="nit"
                      value={nit}
                      onChange={(e) => setNit(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Nombre Comercial</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="tradename"
                      name="name"
                      value={tradename}
                      onChange={(e) => setTradename(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Nombres</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Apellidos</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Email</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <label className="form-label">Numero de Telefono</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Contact Number"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend">Services</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            value="restaurant"
                            name="services"
                          />
                        }
                        label="Restaurant"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            value="hotel"
                            name="services"
                          />
                        }
                        label="Hotel"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            value="transport"
                            name="services"
                          />
                        }
                        label="Transport"
                      />
                    </FormGroup>
                    <FormHelperText>Choose</FormHelperText>
                  </FormControl>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormAddProvider;
