import React, { useState, useEffect, useCallback } from "react";
import AuthUser from "../../features/AuthUser";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Swal from "sweetalert2";

const FormEditBooking = () => {
  const { http, user } = AuthUser();
  const navigate = useNavigate();
  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";

  const [code, setCode] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activity, setActivity] = useState("");
  const [payment, setPayment] = useState("");
  const [people, setPeople] = useState(0);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [partners, setPartners] = useState([]);
  const [services, setServices] = useState("");

  const { id } = useParams();

  useEffect(() => {
    const getBookingId = async () => {
      try {
        const response = await http.get(`/bookings/${id}/edit`);
        setCode(response.data.bookings.code);
        setStartDate(response.data.bookings.checkin);
        setEndDate(response.data.bookings.checkout);
        setActivity(response.data.bookings.destiny);
        setPeople(response.data.bookings.quantity);
        setTotal(response.data.bookings.total);
        setStatus(response.data.bookings.status);
        setPayment(response.data.bookings.payment);
        setName(response.data.bookings.name);
        setLastName(response.data.bookings.last_name);
        setPartners(response.data.partners);
        setServices(response.data.bookings.services);
      } catch (error) {
        if (error.response) {
          console.log(error);
        }
      }
    };
    getBookingId();
  }, []);

  const handlePartnersChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...partners];
    list[index][name] = value;
    setPartners(list);
  };

  const updateBooking = async (e) => {
    e.preventDefault();
    try {
      await http
        .put(`/bookings/${id}`, {
          status: status,
          services: services,
        })
        .then(() => {
          navigate("/bookings");
        });
    } catch (error) {
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  return (
    <div className="row">
      <div className="col">
        <form
          onSubmit={updateBooking}
          className="row g-3 justify-content-md-center border border-dark bg-white"
        >
          <h1 className="title fs-3 fw-bold text-center">
            Bookings Disfruta Buenaventura
          </h1>
          <div className="col-md-6 col-sm-12">
            <label htmlFor="name" className="form-label">
              Name Client
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <label htmlFor="lastname" className="form-label">
              Last Name Client
            </label>
            <input
              type="text"
              className="form-control"
              id="lastname"
              name="lastname"
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
              disabled
            />
          </div>

          <div className="col-md-4 col-sm-12">
            <label htmlFor="activity" className="form-label">
              Activity
            </label>
            <input
              type="text"
              className="form-control"
              id="activity"
              name="activity"
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
              disabled
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label htmlFor="startDate" className="form-label">
              Start Date
            </label>
            <input
              type="text"
              className="form-control"
              id="startDate"
              name="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              disabled
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label htmlFor="endDate" className="form-label">
              End Date
            </label>
            <input
              type="text"
              className="form-control"
              id="endDate"
              name="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              disabled
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label htmlFor="payment" className="form-label">
              Payment Method
            </label>
            <input
              type="text"
              className="form-control"
              name="payment"
              value={payment}
              onChange={(e) => setPayment(e.target.value)}
              disabled
            />
          </div>

          <div className="col-md-4 col-sm-12">
            <label htmlFor="quantity" className="form-label">
              Number of people
            </label>
            <input
              type="text"
              className="form-control"
              id="quantity"
              name="people"
              value={people}
              onChange={(e) => setPeople(e.target.value)}
              disabled
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label htmlFor="total" className="form-label">
              Total
            </label>
            <input
              type="number"
              className="form-control"
              id="total"
              name="total"
              value={total}
              onChange={(e) => setTotal(e.target.value)}
              disabled
            />
          </div>

          <div className="col-md-4 col-sm-12">
            <label htmlFor="status" className="form-label">
              Status
            </label>
            <select
              className="form-select"
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option disabled selected>
                Choose one option
              </option>
              <option value="Pending">Pending</option>
              <option value="Accepted">Accepted</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>

          <br />
          <hr />
          <h2 className="subtitle fs-3 fw-bold text-center">
            Datos de Acompañantes
          </h2>
          <hr />
          <div className="col-md-12 col-sm-12 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Type Identification</th>
                  <th>Number Identification</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Age: If it's a child</th>
                </tr>
              </thead>
              <tbody>
                {partners.map((partner, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <input
                          className="form-control"
                          id="typeId"
                          name="typeId"
                          value={partner.typeId}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          id="numId"
                          placeholder="DNI"
                          name="numId"
                          value={partner.numId}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="First Name"
                          name="name"
                          value={partner.name}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          value={partner.last_name}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Age If it's a child"
                          name="age"
                          value={partner.age}
                          disabled
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <br />
          <h2 className="subtitle fs-3 fw-bold text-center">
            Servicios Adicionales
          </h2>
          <div className="col-md-12 col-sm-12">
            {/* <div className="col-md-12 col-sm-12">
              <h2 className="form-label">Feeding</h2>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  defaultValue="option1"
                />
                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  Breakfast
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  defaultValue="option2"
                />
                <label className="form-check-label" htmlFor="inlineCheckbox2">
                  Lunch
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox3"
                  defaultValue="option3"
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">
                  Dinner
                </label>
              </div>
            </div> */}
            <div className="col-md-12 col-sm-12">
              <textarea
                className="form-control"
                style={{ minWidth: "500px", maxWidth: "100%", width: "100%" }}
                rows="4"
                name="services"
                value={services}
                onChange={(e) => setServices(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="col-12">
            <button type="submit" className="btn btn-secondary">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormEditBooking;
