import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import AuthUser from "../../features/AuthUser";
import { Button, Pagination } from "@mui/material";
import usePagination from "../Utils/Pagination.js";
import { AddCircle, Download } from "@mui/icons-material";
import Swal from "sweetalert2";

const ListBookingsUser = () => {
  const { http, user } = AuthUser();
  const id = user.uuid;
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const getBookings = async () => {
      const response = await http.get(`/list-bookings/${id}`);
      setBookings(response.data);
      // console.log(response.data)
    };
    getBookings();
  }, []);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(bookings.length / PER_PAGE);
  const _DATA = usePagination(bookings, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const voucher = async (id) => {
    try {
      await http.get(`/voucher/${id}`);
    } catch (error) {
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  const openInNewTab = (id) => {
    let url = http.get(`/voucher/${id}`);
    window.open(url, "_blank", "noreferrer");
  };

  const download = (id) => {
    axios({
      url: "http://127.0.0.1:8000/api/download",
      method: "GET",
      responseType: "blob",
      data: {
        code: id,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", id + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log("status", error.response.status);
      });
  };

  return (
    <>
      <h1 class="h3 mb-3">
        <strong>Bookings List</strong>
        {user && (
          <p className="title is-4 is-info">
            {user.name} {user.last_name}
          </p>
        )}
      </h1>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="float-end border-radius-lg pt-2 pb-1">
                <Link to={"/booking"} className="btn btn-success">
                  <AddCircle />
                </Link>
              </div>
            </div>
            <div className="card-body table-responsive">
              <table className="table table-hover my-0">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Voucher</th>
                    <th>Checkin</th>
                    <th>Checkout</th>
                    <th>Plan/Pasadia</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {_DATA.currentData().map((booking, index) => (
                    <tr key={booking.uuid}>
                      <td>{index + 1}</td>
                      <td>
                        {booking.status === "Pending" ? (
                          <Button variant="outlined" disabled>
                            Voucher
                          </Button>
                        ) : (
                          <Link
                            to={`/voucher/${booking.uuid}`}
                            className="btn btn-outline-secondary"
                          >
                            Voucher
                          </Link>
                        )}
                      </td>
                      <td>{moment(booking.checkin).format("YYYY-MM-DD")}</td>
                      <td>{moment(booking.checkout).format("YYYY-MM-DD")}</td>
                      <td>{booking.destiny}</td>
                      <td>{booking.total}</td>
                      <td>
                        {booking.status === "Pending" ? (
                          <span className="badge bg-warning">Pending</span>
                        ) : booking.status === "Accepted" ? (
                          <span className="badge bg-info">Accepted</span>
                        ) : booking.status === "Completed" ? (
                          <span className="badge bg-success">Comlpeted</span>
                        ) : (
                          <span className="badge bg-danger">Cancelled</span>
                        )}
                      </td>
                      <td>
                        <Button
                          onClick={() => download(`${booking.code}`)}
                          variant="outlined"
                          color="primary"
                        >
                          <Download />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mx-auto py-2" style={{ width: "200px" }}>
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListBookingsUser;
