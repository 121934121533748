import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import MUIDataTable from "mui-datatables";
import { Button, Pagination, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Swal from "sweetalert2";
import AuthUser from "../../features/AuthUser";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  LabelList,
} from "recharts";

const CompareDateReport = () => {
  const { http } = AuthUser();
  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";
  const [isOpened, setIsOpened] = useState(false);

  const [dateStartRange, setDateStartRange] = useState([null, null]);
  const [startDate, endDate] = dateStartRange;
  const [dateEndRange, setDateEndRange] = useState([null, null]);
  const [dateStart, dateEnd] = dateEndRange;

  const [bookings, setBookings] = useState([]);
  const [destiny, setDestiny] = useState([
    {
      start: "",
      end: "",
    },
  ]);

  const [bookStart, setBookStart] = useState([]);
  const [bookEnd, setBookEnd] = useState([]);

  const [people, setPeople] = useState([]);
  const [daysMonth, setdaysMonth] = useState([]);

  const onChangeRange = (dates, dateStrings) => {
    setDateStartRange(dateStrings);
  };

  const onChangeRangeEnd = (dates, dateStrings) => {
    setDateEndRange(dateStrings);
  };

  const toggle = () => {
    setIsOpened(true);
  };

  const fetchData = async () => {
    try {
      const response = await http.get(
        `/dateRangeCompare/${startDate}/${endDate}/${dateStart}/${dateEnd}`
      );

      /* const array1 = response.data.bookingsdestiny;
      const array2 = response.data.bookdestiny;
      const array3 = array1.concat(array2); */

      /* setDestiny([{
        start: response.data.reservas.reserva,
        end: response.data.reservas1.reserva,
      }]); */

      // console.log(array3)

      setBookStart(response.data.bookingsdestiny);
      setBookEnd(response.data.bookdestiny);
    } catch (error) {
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    console.log(bookStart);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">
                Busqueda comparada por un rango de fechas
              </h5>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-center">
                <div className="p-2">
                  <RangePicker format={dateFormat} onChange={onChangeRange} />
                </div>
                <div className="p-2">
                  <RangePicker
                    format={dateFormat}
                    onChange={onChangeRangeEnd}
                  />
                </div>
                <div className="p-2">
                  <Button
                    onClick={() => {
                      fetchData();
                      toggle();
                    }}
                    variant="contained"
                    color="success"
                    endIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpened === true && (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title text-center mb-0">
                  Total de reservas por destino
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6 col-xxl-3 d-flex order-1 order-xxl-1">
                    <div className="card flex-fill w-100">
                      <div className="card-header">
                        <h5 className="card-title text-center mb-0">
                          {startDate} - {endDate}
                        </h5>
                      </div>
                      <div className="card-body d-flex">
                        <div className="align-self-center w-100">
                          <div className="py-3">
                            <div className="chart chart-sm">
                              <ResponsiveContainer
                                width="100%"
                                height="100%"
                                aspect={1}
                              >
                                <BarChart
                                  width={500}
                                  height={300}
                                  data={bookStart}
                                  margin={{
                                    top: 15,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" angle={-45} stroke="#000" />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend />
                                  <Bar
                                    dataKey="total_bookings"
                                    barSize={15}
                                    fill="#413ea0"
                                  >
                                    <LabelList
                                      dataKey="total_bookings"
                                      position="top"
                                    />
                                  </Bar>
                                </BarChart>
                              </ResponsiveContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xxl-3 d-flex order-2 order-xxl-2">
                    <div className="card flex-fill">
                      <div className="card-header">
                        <h5 className="card-title text-center mb-0">
                          {dateStart} - {dateEnd}
                        </h5>
                      </div>
                      <div className="card-body d-flex">
                        <div className="align-self-center w-100">
                          <div className="chart chart-sm">
                            <ResponsiveContainer
                              width="100%"
                              height="100%"
                              aspect={1}
                            >
                              <BarChart
                                width={500}
                                height={300}
                                data={bookEnd}
                                margin={{
                                  top: 15,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" angle={-45} stroke="#000" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar
                                  dataKey="total_bookings"
                                  barSize={15}
                                  fill="#82ca9d"
                                >
                                  <LabelList
                                    dataKey="total_bookings"
                                    position="top"
                                  />
                                </Bar>
                              </BarChart>
                            </ResponsiveContainer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CompareDateReport;
