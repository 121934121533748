import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthUser from "../features/AuthUser";

const ResetPassword = () => {
  const { http } = AuthUser();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const reset = async (e) => {
    e.preventDefault();
    try {
      const response = await http.post("/forget-password", {email: email});

      if (response.status === 200) {
        Swal.fire({
          text: response.data.success,
          icon: "success",
        }).then((res) => {
          if (res) {
            navigate("/login");
          }
        });
      }

    } catch (error) {
      Swal.fire({
        text: "Error, Token cannot be sent!",
        icon: "error",
      });
      
    }
    
  };

  return (
    <main
      className="d-flex w-100"
      style={{
        backgroundImage: `url("/img/playa-pianguita-buenaventura.jpg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className="card">
                <div className="card-body">
                  <div className="m-sm-4">
                    <div className="text-center">
                      <img
                        src="/brand/logo-disfrutabun.svg"
                        alt="Logo"
                        className="img-fluid"
                        width={132}
                        height={132}
                      />
                    </div>
                    <form onSubmit={reset}>
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input
                          className="form-control form-control-lg"
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter your email"
                        />
                      </div>
                      <div className="text-center mt-3">
                        <button
                          className="btn btn-lg btn-success"
                          type="submit"
                        >
                          Send Password Reset Link
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
