import React, {useEffect} from 'react'
import Layout from '../Layout';
import FormActivityCrud from '../../components/Activity/FormActivityCrud';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../features/AuthUser';

const Activities = () => {
    const navigate = useNavigate();
    const { token } = AuthUser();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    })

    return (
        <div>
            <Layout>
                <FormActivityCrud />
            </Layout>
        </div>
    )
}

export default Activities