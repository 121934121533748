import React, { useState, useEffect } from "react";
import AuthUser from "../../features/AuthUser";
import { Delete, ModeEdit, AddCircle } from "@mui/icons-material";
import {
  Pagination,
  Modal,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import usePagination from "../Utils/Pagination.js";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";

const FormActivityCrud = () => {
  const { http } = AuthUser();
  const [activities, setActivities] = useState([]);
  const [types, setTypes] = useState([]);
  const [destinies, setDestinies] = useState([]);
  const [providers, setProviders] = useState([]);

  const [name, setName] = useState("");
  const [buyPrice, setBuyPrice] = useState();
  const [price, setPrice] = useState();
  const [type, setType] = useState();
  const [destiny, setDestiny] = useState();
  const [provider, setProvider] = useState();
  const [feeding, setFeeding] = useState();
  const [transport, setTransport] = useState();
  const [secure, setSecure] = useState();

  const [idAct, setIdAct] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const { id } = "";

  const getActivities = async () => {
    const response = await http.get("/activities");
    setActivities(response.data);
  };

  const fetchTypes = async () => {
    const response = await http.get("/gettypes");
    setTypes(response.data);
  };

  const fetchDestinies = async () => {
    const response = await http.get("/destinies");
    setDestinies(response.data);
  };

  const fetchProviders = async () => {
    const response = await http.get("/providers");
    setProviders(response.data);
  };

  useEffect(() => {
    getActivities();
    fetchTypes();
    fetchDestinies();
    fetchProviders();
  }, []);

  const count = Math.ceil(activities.length / PER_PAGE);
  const _DATA = usePagination(activities, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const submitActivity = async (e) => {
    e.preventDefault();
    try {
      await http
        .post("/activities", {
          name: name,
          buy_price: buyPrice,
          price: price,
          destiny_id: destiny,
          type_id: type,
          provider_id: provider,
          feeding: feeding,
          transport: transport,
          secure: secure

        })
        .then(() => {
          setName("");
          setBuyPrice("");
          setPrice("");
          setDestiny("");
          setType("");
          setProvider("");
          setFeeding("");
          setTransport("");
          setSecure("");
          handleClose();
          getActivities();
        });
    } catch (error) {
      handleClose();
      Swal.fire({
        text: "Error, data not saved",
        icon: "error",
      });
    }
  };

  const editActivity = async (id) => {
    try {
      const response = await http.get(`/activities/${id}/edit`);
      handleOpenEdit();
      setName(response.data.name);
      setBuyPrice(response.data.buy_price);
      setPrice(response.data.price);
      setDestiny(response.data.destiny_id)
      setType(response.data.type_id);
      setProvider(response.data.provider_id);
      setFeeding(response.data.feeding);
      setTransport(response.data.transport);
      setSecure(response.data.secure);
      setIdAct(response.data.uuid);
    } catch (error) {
      handleCloseEdit();
      Swal.fire({
        text: "Error, data not found",
        icon: "error",
      });
      console.log(error);
    }
  };

  const submitUpdate = async (e) => {
    e.preventDefault();
    try {
      await http
        .put(`/activities/${idAct}`, {
          name: name,
          buy_price: buyPrice,
          price: price,
          destiny_id: destiny,
          type_id: type,
          provider_id: provider,
          feeding: feeding,
          transport: transport,
          secure: secure
        })
        .then(() => {
          handleCloseEdit();
          getActivities();
        });
    } catch (error) {
      // console.log(error)
      handleCloseEdit();
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  const deleteType = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        http.delete(`/activities/${id}`);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        getActivities();
      }
    });
  };

  const style = {
    position: "absolute",
    top: "60%",
    left: "50%",
    overflow: "scroll",
    height: "100%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <h1 className="h3 mb-3">Activities Plans or Pasadias</h1>
      <div className="row">
        <div className="col-12">
          <div className="card mb-2 pb-2">
            <div className="card-header">
              <div className="float-end border-radius-lg pt-2 pb-1">
                <Button className="btn btn-success" onClick={handleOpen}>
                  <AddCircle />
                </Button>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive p-0">
                <table className="table table-hover my-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Destiny</th>
                      <th>Type of Activity</th>
                      <th>Name</th>
                      <th>Sale Price</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_DATA.currentData().map((activity, index) => (
                      <tr key={activity.uuid}>
                        <td>{index + 1}</td>
                        <td>{activity.destiny}</td>
                        <td>{activity.type}</td>
                        <td>{activity.name}</td>
                        <td>{activity.price}</td>
                        <td>
                          <Button
                            onClick={() => editActivity(`${activity.uuid}`)}
                            variant="outlined"
                            color="success"
                          >
                            <ModeEdit />
                          </Button>
                          <Button
                            onClick={() => deleteType(activity.uuid)}
                            variant="outlined"
                            color="error"
                          >
                            <Delete />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mx-auto my-2">
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Actividades
          </Typography>
          <Form onSubmit={submitActivity}>
            <Form.Group className="mb-3" controlId="destinySelect">
              <Form.Label>Destino</Form.Label>
              <Form.Select
                value={destiny}
                onChange={(e) => setDestiny(e.target.value)}
                aria-label="Default select example"
              >
                {destinies.map((destiny) => (
                  <option value={destiny.id}>{destiny.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="typeSelect">
              <Form.Label>Tipo de Actividad</Form.Label>
              <Form.Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                aria-label="Default select example"
              >
                {types.map((type) => (
                  <option value={type.id}>{type.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="providerSelect">
              <Form.Label>Alojamiento</Form.Label>
              <Form.Select
                value={provider}
                onChange={(e) => setProvider(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Ninguna</option>
                {providers.map((provider) => (
                  <option value={provider.id}>{provider.tradename}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="providerSelect">
              <Form.Label>Alimentacion</Form.Label>
              <Form.Select
                value={feeding}
                onChange={(e) => setFeeding(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Ninguna</option>
                {providers.map((provider) => (
                  <option value={provider.id}>{provider.tradename}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="providerSelect">
              <Form.Label>Transporte</Form.Label>
              <Form.Select
                value={transport}
                onChange={(e) => setTransport(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Ninguna</option>
                {providers.map((provider) => (
                  <option value={provider.id}>{provider.tradename}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="providerSelect">
              <Form.Label>Seguro de viaje</Form.Label>
              <Form.Select
                value={secure}
                onChange={(e) => setSecure(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Ninguna</option>
                {providers.map((provider) => (
                  <option value={provider.id}>{provider.tradename}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter type of activity"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Precio Compra</Form.Label>
              <Form.Control
                type="number"
                placeholder=" buy price of activity"
                value={buyPrice}
                onChange={(e) => setBuyPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Precio Venta</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter sale price of activity"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
            <Button variant="outlined" color="primary" type="submit" block className="mb-4">
              Guardar
            </Button>
          </Form>
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar Actividad
          </Typography>
          <Form onSubmit={submitUpdate}>
          <Form.Group className="mb-3" controlId="destinySelect">
              <Form.Label>Destino</Form.Label>
              <Form.Select
                value={destiny}
                onChange={(e) => setDestiny(e.target.value)}
                aria-label="Default select example"
              >
                {destinies.map((destiny) => (
                  <option value={destiny.id}>{destiny.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="typeSelect">
              <Form.Label>Tipo de Actividad</Form.Label>
              <Form.Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                aria-label="Default select example"
              >
                {types.map((type) => (
                  <option value={type.id}>{type.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="providerSelect">
              <Form.Label>Alojamiento</Form.Label>
              <Form.Select
                value={provider}
                onChange={(e) => setProvider(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Ninguna</option>
                {providers.map((provider) => (
                  <option value={provider.id}>{provider.tradename}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="providerSelect">
              <Form.Label>Alimentacion</Form.Label>
              <Form.Select
                value={feeding}
                onChange={(e) => setFeeding(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Ninguna</option>
                {providers.map((provider) => (
                  <option value={provider.id}>{provider.tradename}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="providerSelect">
              <Form.Label>Transporte</Form.Label>
              <Form.Select
                value={transport}
                onChange={(e) => setTransport(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Ninguna</option>
                {providers.map((provider) => (
                  <option value={provider.id}>{provider.tradename}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="providerSelect">
              <Form.Label>Seguro de viaje</Form.Label>
              <Form.Select
                value={secure}
                onChange={(e) => setSecure(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Ninguna</option>
                {providers.map((provider) => (
                  <option value={provider.id}>{provider.tradename}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter type of activity"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Precio Compra</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter buy price of activity"
                value={buyPrice}
                onChange={(e) => setBuyPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Precio Venta</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter sale price of activity"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
            <Button variant="outlined" color="primary" type="submit" block className="mb-4">
              Editar
            </Button>
          </Form>
        </Box>
      </Modal>

    </>
  );
};

export default FormActivityCrud;
