import React, { useState } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import AuthUser from "../features/AuthUser.js";
import {
  GridView,
  CalendarMonth,
  Close,
  DateRange,
  Group,
  PieChart,
  FormatListBulleted,
  ManageAccounts,
  Analytics,
  BarChart,
  Assignment,
  Logout,
  TableRows,
  TrendingUp,
  AlignVerticalCenter,
  FormatListNumbered,
  PriceChange
} from "@mui/icons-material";
import Button from "react-bootstrap/Button";

const Sidebar = (props) => {
  const { token, logout, user } = AuthUser();

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const logoutUser = () => {
    if (token !== undefined) {
      logout();
    }
  };

  return (
    <>
      <nav id="sidebar" className={`sidebar js-sidebar ${props.style} `}>
        <div className="sidebar-content js-simplebar">
          <a className="sidebar-brand" href="!#">
            <span className="align-middle">DisfruraBuenaventura</span>
          </a>
          {user && user.role === "admin" && (
            <ul className="sidebar-nav">
              <li className="sidebar-header">Pages</li>
              <li className={splitLocation[1] === "dashboard" ? "sidebar-item active" : ""}>
                <Link to={"/dashboard"} className="sidebar-link">
                  <GridView className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Dashboard</span>
                </Link>
              </li>
              <li className={splitLocation[1] === "clients" ? "sidebar-item active" : ""}>
                <Link to={"/clients"} className="sidebar-link">
                  <Group className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Clients</span>
                </Link>
              </li>
              <li className={splitLocation[1] === "providers" ? "sidebar-item active" : ""}>
                <Link to={"/providers"} className="sidebar-link">
                  <Group className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Providers</span>
                </Link>
              </li>
              <li className={splitLocation[1] === "activities" ? "sidebar-item active" : ""}>
                <Link to={"/activities"} className="sidebar-link">
                  <Assignment className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Activities</span>
                </Link>
              </li>
              <li className={splitLocation[1] === "destinations" ? "sidebar-item active" : ""}>
                <Link to={"/destinations"} className="sidebar-link">
                  <FormatListNumbered className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Destinations</span>
                </Link>
              </li>
              <li className={splitLocation[1] === "types" ? "sidebar-item active" : ""}>
                <Link to={"/types"} className="sidebar-link">
                  <FormatListBulleted className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Types Activity</span>
                </Link>
              </li>
              <li className="sidebar-header">Components</li>
              <li className={splitLocation[1] === "bookings" ? "sidebar-item active" : ""}>
                <Link to={"/bookings"} className="sidebar-link">
                  <DateRange className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Bookings</span>
                </Link>
              </li>
              <li className={splitLocation[1] === "calendar" ? "sidebar-item active" : ""}>
                <Link to={"/calendar"} className="sidebar-link">
                  <CalendarMonth className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Calendar</span>
                </Link>
              </li>
              <li className={splitLocation[1] === "dues" ? "sidebar-item active" : ""}>
                <Link to={"/dues"} className="sidebar-link">
                  <PriceChange className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Due</span>
                </Link>
              </li>
              <li className="sidebar-header">Analytics &amp; Graphics</li>
              <li className={splitLocation[1] === "monthly" ? "sidebar-item active" : ""}>
                <Link to={"/monthly"} className="sidebar-link">
                  <TableRows className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Tables</span>
                </Link>
              </li>
              <li className={splitLocation[1] === "graphics" ? "sidebar-item active" : ""}>
                <Link to={"/graphics"} className="sidebar-link">
                  <BarChart className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Graphics</span>
                </Link>
              </li>
              <li className="sidebar-item">
                <a
                  className="sidebar-link"
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#subMenu"
                  aria-expanded="false"
                  aria-controls="subMenu"
                >
                  <Analytics className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Reports</span>
                </a>
                <div id="subMenu" className="collapse" data-parent="#sidebar">
                  <ul className="nav flex-column">
                    <li className={splitLocation[1] === "reports-destiny" ? "sidebar-item active" : ""}>
                      <Link to={"/reports-destiny"} className="sidebar-link">
                        <PieChart
                          className="align-middle"
                          sx={{ fontSize: 20 }}
                        />
                        <span className="align-middle">Destiny Report</span>
                      </Link>
                    </li>
                    <li className={splitLocation[1] === "reports-date-range" ? "sidebar-item active" : ""}>
                      <Link to={"/reports-date-range"} className="sidebar-link">
                        <TrendingUp
                          className="align-middle"
                          sx={{ fontSize: 20 }}
                        />
                        <span className="align-middle">Date Report</span>
                      </Link>
                    </li>
                    <li className={splitLocation[1] === "reports-date-compare" ? "sidebar-item active" : ""}>
                      <Link
                        to={"/reports-date-compare"}
                        className="sidebar-link"
                      >
                        <AlignVerticalCenter
                          className="align-middle"
                          sx={{ fontSize: 20 }}
                        />
                        <span className="align-middle">Compare Report</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="sidebar-header">Authentication</li>
              <li className={splitLocation[1] === "users" ? "sidebar-item active" : ""}>
                <Link to={"/users"} className="sidebar-link">
                  <Group className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Users</span>
                </Link>
              </li>
              <li className={splitLocation[1] === "profile" ? "sidebar-item active" : ""}>
                <Link to={"/profile"} className="sidebar-link">
                  <ManageAccounts
                    className="align-middle"
                    sx={{ fontSize: 20 }}
                  />
                  <span className="align-middle">Profile</span>
                </Link>
              </li>
            </ul>
          )}

          {user && user.role === "user" && (
            <ul className="sidebar-nav">
              <li className="sidebar-header">Components</li>
              <li className={splitLocation[1] === "bookings-by-user" ? "sidebar-item active" : ""}>
                <Link to={"/bookings-by-user"} className="sidebar-link">
                  <DateRange className="align-middle" sx={{ fontSize: 20 }} />
                  <span className="align-middle">Bookings</span>
                </Link>
              </li>
              <li className={splitLocation[1] === "calendar" ? "sidebar-item active" : ""}>
                <Link to={"/calendar"} className="sidebar-link">
                  <CalendarMonth
                    className="align-middle"
                    sx={{ fontSize: 20 }}
                  />
                  <span className="align-middle">Calendar</span>
                </Link>
              </li>
              <li className="sidebar-header">Authentication</li>
              <li className={splitLocation[1] === "profile" ? "sidebar-item active" : ""}>
                <Link to={"/profile"} className="sidebar-link">
                  <ManageAccounts
                    className="align-middle"
                    sx={{ fontSize: 20 }}
                  />
                  <span className="align-middle">Profile</span>
                </Link>
              </li>
              <li className={splitLocation[1] === "clients/edit" ? "sidebar-item active" : ""}>
                <Link to={"/clients/edit"} className="sidebar-link">
                  <ManageAccounts
                    className="align-middle"
                    sx={{ fontSize: 20 }}
                  />
                  <span className="align-middle">Setting</span>
                </Link>
              </li>
            </ul>
          )}

          <div className="sidebar-item px-4 mx-2 pt-2">
            <div className="d-grid">
              <Button
                onClick={logoutUser}
                className="align-middle"
                variant="outline-light"
              >
                <Logout className="align-middle" sx={{ fontSize: 20 }} />
                Logout
              </Button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
