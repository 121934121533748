import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthUser from "../features/AuthUser";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { http, setToken } = AuthUser();
  const navigate = useNavigate();

  const Auth = (e) => {
    e.preventDefault();
    http.post("/login", { email: email, password: password })
      .then((res) => {
        setToken(res.data.user, res.data.access_token);
        if (res.data.user.role === "user") {
          navigate("/booking");
        } else {
          navigate("/dashboard");
        }
      })
      .catch(({ response }) => {
        if (response.status === 402) {
          Swal.fire({
            text: response.data.error,
            icon: "error",
          });
        }
      });
  };

  return (
    <>
      <main className="d-flex w-100" style={{ 
        backgroundImage: `url("/img/playa-pianguita-buenaventura.jpg")`, 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: "100% 100%",
        }}>
        <div className="container d-flex flex-column">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      <div className="text-center">
                        <img
                          src="/brand/logo-disfrutabun.svg"
                          alt="Logo"
                          className="img-fluid"
                          width={132}
                          height={132}
                        />
                      </div>
                      <form onSubmit={Auth}>
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <input
                            className="form-control form-control-lg"
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Password</label>
                          <input
                            className="form-control form-control-lg"
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                          />
                          <small>
                            <Link to={"/forget-password"}>Forgot password?</Link>
                          </small>
                        </div>
                        <div>
                          <label className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue="remember-me"
                              name="remember-me"
                              defaultChecked
                            />
                            <span className="form-check-label">
                              Remember me
                            </span>
                          </label>
                        </div>
                        <div className="text-center mt-3">
                          <button
                            className="btn btn-lg btn-success"
                            type="submit"
                          >
                            Sign in
                          </button>
                        </div>
                        <label className="pt-2">
                          No tienes una cuenta aun? <Link to={"/"}>Registrate</Link>
                        </label>
                        <p className="mt-5 mb-3 text-center text-muted">
                          Disfruta Buenaventura &copy; 2023
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Login;
