import React, { useState, useEffect } from "react";
import AuthUser from "../../features/AuthUser";
import { Delete, ModeEdit, AddCircle } from "@mui/icons-material";
import { Pagination, Modal, Typography, Button, Box } from "@mui/material";
import usePagination from "../Utils/Pagination.js";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";

const FormDestinyCrud = () => {
  const { http } = AuthUser();
  const [destinies, setDestinies] = useState([]);
  const [name, setName] = useState("");

  const [idDes, setIdDes] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [page, setPage] = useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(destinies.length / PER_PAGE);
  const _DATA = usePagination(destinies, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    getDestinations();
  }, []);

  const getDestinations = async () => {
    const response = await http.get("/destinies");
    setDestinies(response.data);
  };

  const submitType = async (e) => {
    e.preventDefault();
    try {
      await http
        .post("/destinies", {
          name: name,
        })
        .then(() => {
          setName("");
          handleClose();
          getDestinations();
        });
    } catch (error) {
      handleClose();
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  const handleShowEdit = async (id) => {
    try {
      const response = await http.get(`/destinies/${id}/edit`);
      handleOpenEdit();
      setName(response.data.name);
      setIdDes(response.data.uuid);
    } catch (error) {
      handleCloseEdit();
      Swal.fire({
        text: "Error, data not found",
        icon: "error",
      });
    }
  };

  const submitUpdate = async (e) => {
    e.preventDefault();
    try {
      await http
        .put(`/destinies/${idDes}`, {
          name: name,
        })
        .then(() => {
          setName("");
          handleCloseEdit();
          getDestinations();
        });
    } catch (error) {
      handleCloseEdit();
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  const deleteType = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        http.delete(`/destinies/${id}`);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        getDestinations();
      }
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <h1 className="h3 mb-3">Destinations</h1>
      <div className="row">
        <div className="col-12">
          <div className="card mb-2 pb-2">
            <div className="card-header">
              <div className="float-end border-radius-lg pt-2 pb-1">
                <Button className="btn btn-success" onClick={handleOpen}>
                  <AddCircle />
                </Button>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive p-0">
                <table className="table table-hover my-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_DATA.currentData().map((destiny, index) => (
                      <tr key={destiny.uuid}>
                        <td>{index + 1}</td>
                        <td>{destiny.name}</td>
                        <td>
                          <Button
                            onClick={() => handleShowEdit(destiny.uuid)}
                            variant="outlined"
                            color="success"
                          >
                            <ModeEdit />
                          </Button>
                          <Button
                            onClick={() => deleteType(destiny.uuid)}
                            variant="outlined"
                            color="error"
                          >
                            <Delete />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mx-auto my-2">
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Destiny
          </Typography>
          <Form onSubmit={submitType}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter type of activity"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Button variant="outlined" color="primary" type="submit" block>
              Save
            </Button>
          </Form>
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Destiny
          </Typography>
          <Form onSubmit={submitUpdate}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter type of activity"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Button variant="outlined" color="primary" type="submit" block>
              Update
            </Button>
          </Form>
        </Box>
      </Modal>
    </>
  );
};

export default FormDestinyCrud;
