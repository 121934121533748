import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import AuthUser from "../../features/AuthUser";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import { Button, Pagination, Avatar } from "@mui/material";
import usePagination from "../Utils/Pagination.js";

const UserProfile = () => {
  const { http, user, logout } = AuthUser();
  const fullname = user.name + " " + user.last_name;
  const [meter, setMeter] = useState(false);

  const [country, setCountry] = useState("");
  const [iso, setIso] = useState("");

  const [name, setName] = useState(user.name);
  const [lastName, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const id = user.uuid;

  const [bookings, setBookings] = useState([]);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g;
  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*+_-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{8,}/g; // eight characters or more

  const passwordTracker = {
    uppercase: newPassword.match(atLeastOneUppercase),
    lowercase: newPassword.match(atLeastOneLowercase),
    number: newPassword.match(atLeastOneNumeric),
    specialChar: newPassword.match(atLeastOneSpecialChar),
    eightCharsOrGreater: newPassword.match(eightCharsOrMore),
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  useEffect(() => {
    const getBookings = async () => {
      const response = await http.get(`/list-bookings/${id}`);
      setBookings(response.data);
    };
    getBookings();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await http.get(`/clients/${id}/show`);
      setCountry(response.data.country);
      setIso(response.data.iso);
    };
    fetchData();
  });

  const [page, setPage] = useState(1);
  const PER_PAGE = 5;

  const count = Math.ceil(bookings.length / PER_PAGE);
  const _DATA = usePagination(bookings, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleValidation = () => {
    if (confirmPassword || (newPassword && confirmPassword.length > 0)) {
      if (confirmPassword !== newPassword) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  const changePassword = async (e) => {
    e.preventDefault();
    try {
      const response = await http.post("/changePassword", {
        old_password: currentPassword,
        password: newPassword,
        uuid: user.uuid,
      });

      if (response) {
        Swal.fire("OK", "Changed password.", "success").then(() => {
          logout();
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        Swal.fire({
          text: error.response.data.error,
          icon: "error",
        });
      } else {
        Swal.fire({
          text: "Error, data cannot be processed",
          icon: "error",
        });
      }
    }
  };

  const submitUser = async (e) => {
    e.preventDefault();
    try {
      await http
        .put(`/users/${id}`, {
          name: name,
          last_name: lastName,
          email: email,
          phone: phone,
        })
        .then((response) => {
          if (response) {
            Swal.fire("OK", "User data Updated Successfully", "success").then(
              () => {
                logout();
              }
            );
          }
        });

      /* console.log({
        uuid: id,
        name: name,
        last_name: lastName,
        email: email,
        phone: phone,
      }) */
    } catch (error) {
      console.log(error);
      Swal.fire({
        text: "Error, data cannot be processed",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="mb-3">
        <h1 className="h3 d-inline align-middle">Profile</h1>
      </div>

      {user && user.role === "admin" && (
        <div className="row">
          <div className="col-md-3 col-xl-3">
            <div className="card mb-3">
              <div className="card-header">
                <h5 className="card-title mb-0">Profile Details</h5>
              </div>
              <div className="card-body text-center">
                <Avatar
                  alt={fullname}
                  className="avatar-profile"
                  {...stringAvatar(fullname)}
                />
                <h5 className="card-title mb-0 pt-2">{fullname}</h5>
                <div className="text-muted mb-2">{user.role}</div>
              </div>
              <hr className="my-0" />
            </div>
          </div>

          <div className="col-md-5 col-xl-5">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Edit User Data</h5>
              </div>
              <div className="card-body h-100">
                <Form onSubmit={submitUser}>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="last_name">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    block
                  >
                    Update
                  </Button>
                </Form>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xl-4">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Change Password</h5>
              </div>
              <div className="card-body h-100">
                <form
                  onSubmit={changePassword}
                  className="row g-3 justify-content-md-center"
                >
                  <div className="col-12">
                    <label className="form-label">Current Password</label>
                    <div className="control">
                      <input
                        className="form-control"
                        type="password"
                        id="current-password"
                        name="current-password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="form-label">New Password</label>
                    <div className="control">
                      <input
                        className="form-control"
                        type="password"
                        id="new_password"
                        name="new_password"
                        value={newPassword}
                        onFocus={() => setMeter(true)}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    {meter && (
                      <div>
                        <div className="password-strength-meter"></div>
                        <div>
                          {passwordStrength < 5 && "Must contain "}
                          {!passwordTracker.uppercase && "uppercase, "}
                          {!passwordTracker.lowercase && "lowercase, "}
                          {!passwordTracker.specialChar &&
                            "special character, "}
                          {!passwordTracker.number && "number, "}
                          {!passwordTracker.eightCharsOrGreater &&
                            "eight characters or more"}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <label className="form-label">Confirm New Password</label>
                    <div className="control">
                      <input
                        className="form-control"
                        type="password"
                        id="new_password_confirmation"
                        name="new_password_confirmation"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onKeyUp={handleValidation}
                        required
                      />
                    </div>
                    <p className="text-danger">{confirmPasswordError}</p>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                    >
                      Change Password
                    </button>
                  </div>
                </form>
                <style jsx>
                  {`
                    .password-strength-meter {
                      height: 0.3rem;
                      background-color: lightgrey;
                      border-radius: 3px;
                      margin: 0.5rem 0;
                    }

                    .password-strength-meter::before {
                      content: "";
                      background-color: ${[
                        "red",
                        "orange",
                        "#03a2cc",
                        "#03a2cc",
                        "#0ce052",
                      ][passwordStrength - 1] || ""};
                      height: 100%;
                      width: ${(passwordStrength / 5) * 100}%;
                      display: block;
                      border-radius: 3px;
                      transition: width 0.2s;
                    }
                  `}
                </style>
              </div>
            </div>
          </div>
        </div>
      )}

      {user && user.role === "user" && (
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xl-6">
            <div className="card mb-3">
              <div className="card-header">
                <h5 className="card-title mb-0">Profile Details</h5>
              </div>
              <div className="card-body text-center">
                <Avatar
                  alt={fullname}
                  className="avatar-profile"
                  {...stringAvatar(fullname)}
                />
                <h5 className="card-title mb-0">{fullname}</h5>
                <div className="text-muted mb-2">{user.role}</div>
              </div>
              <hr className="my-0" />
              <div className="card-body">
                <h5 className="h6 card-title">About</h5>
                <ul className="list-unstyled mb-0">
                  <li className="mb-1">
                    <span data-feather="home" className="feather-sm me-1" />
                    Lives in {country}, {iso}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-xl-6">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Change Password</h5>
              </div>
              <div className="card-body h-100">
                <form
                  onSubmit={changePassword}
                  className="row g-3 justify-content-md-center"
                >
                  <div className="col-12">
                    <label className="form-label">Current Password</label>
                    <div className="control">
                      <input
                        className="form-control"
                        type="password"
                        id="current-password"
                        name="current-password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="form-label">New Password</label>
                    <div className="control">
                      <input
                        className="form-control"
                        type="password"
                        id="new_password"
                        name="new_password"
                        value={newPassword}
                        onFocus={() => setMeter(true)}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    {meter && (
                      <div>
                        <div className="password-strength-meter"></div>
                        <div>
                          {passwordStrength < 5 && "Must contain "}
                          {!passwordTracker.uppercase && "uppercase, "}
                          {!passwordTracker.lowercase && "lowercase, "}
                          {!passwordTracker.specialChar &&
                            "special character, "}
                          {!passwordTracker.number && "number, "}
                          {!passwordTracker.eightCharsOrGreater &&
                            "eight characters or more"}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <label className="form-label">Confirm New Password</label>
                    <div className="control">
                      <input
                        className="form-control"
                        type="password"
                        id="new_password_confirmation"
                        name="new_password_confirmation"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onKeyUp={handleValidation}
                        required
                      />
                    </div>
                    <p className="text-danger">{confirmPasswordError}</p>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                    >
                      Change Password
                    </button>
                  </div>
                </form>
                <style jsx>
                  {`
                    .password-strength-meter {
                      height: 0.3rem;
                      background-color: lightgrey;
                      border-radius: 3px;
                      margin: 0.5rem 0;
                    }

                    .password-strength-meter::before {
                      content: "";
                      background-color: ${[
                        "red",
                        "orange",
                        "#03a2cc",
                        "#03a2cc",
                        "#0ce052",
                      ][passwordStrength - 1] || ""};
                      height: 100%;
                      width: ${(passwordStrength / 5) * 100}%;
                      display: block;
                      border-radius: 3px;
                      transition: width 0.2s;
                    }
                  `}
                </style>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-xl-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Bookings</h5>
              </div>
              <div className="card-body h-100 table-responsive">
                <table className="table table-hover my-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Plan/Pasadia</th>
                      <th>Checkin</th>
                      <th>Checkout</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_DATA.currentData().map((booking, index) => (
                      <tr key={booking.uuid}>
                        <td>{index + 1}</td>
                        <td>
                          {booking.status === "Pending" ? (
                            <Button variant="outlined" disabled>
                              {booking.destiny}
                            </Button>
                          ) : (
                            <Link
                              to={`/voucher/${booking.uuid}`}
                              className="btn btn-outline-secondary"
                            >
                              {booking.destiny}
                            </Link>
                          )}
                        </td>
                        <td>{moment(booking.checkin).format("YYYY-MM-DD")}</td>
                        <td>{moment(booking.checkout).format("YYYY-MM-DD")}</td>
                        <td>
                          {booking.status === "Pending" ? (
                            <span className="badge bg-warning">Pending</span>
                          ) : booking.status === "Accepted" ? (
                            <span className="badge bg-info">Accepted</span>
                          ) : booking.status === "Completed" ? (
                            <span className="badge bg-success">Comlpeted</span>
                          ) : (
                            <span class="badge bg-danger">Cancelled</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mx-auto py-2" style={{ width: "200px" }}>
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
