import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import AuthUser from "../../features/AuthUser";
import Select from "react-select";
import { Modal, Typography, Button, Box } from "@mui/material";

const FormAddUserClient = () => {
  const { http, setToken } = AuthUser();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [typeId, setTypeId] = useState("");
  const [numId, setNumId] = useState("");
  const [country, setCountry] = useState();
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [emergency, setEmergency] = useState("");
  const [agree, setAgree] = useState(false);

  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    const response = await http.get("/countries");
    const data = response.data.countries;

    const options = data.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    setCountries(options);
  };

  const canBeSubmitted = () => {
    const isValid = agree;

    if (isValid) {
      document.getElementById("submitButton").removeAttribute("disabled");
    } else {
      document.getElementById("submitButton").setAttribute("disabled", true);
    }
  };

  useEffect(() => canBeSubmitted());

  const nextBooking = async (e) => {
    e.preventDefault();
    try {
      await http.post("/clients", {
        name: name,
        last_name: lastName,
        email: email,
        password: numId,
        phone: phone,
        type_id: typeId,
        dni: numId,
        country_id: country,
        city: city,
        address: address,
        emergency: emergency,
      });

      await http
        .post("/login", {
          email: email,
          password: numId,
        })
        .then((res) => {
          setToken(res.data.user, res.data.access_token);
          if (res.data.user.role === "user") {
            navigate("/booking");
          }
        });
    } catch (error) {
      console.log(error);
      if (error.response.status === 402) {
        Swal.fire({
          text: "Customer is already registered, please login",
          icon: "error",
        }).then(() => {
          navigate("/login");
        });
      } else {
        Swal.fire({
          text: "Error, data cannot be recorded",
          icon: "error",
        });
      }
    }
  };

  const style = {
    position: "absolute",
    top: "60%",
    left: "50%",
    overflow: "scroll",
    height: "100%",

    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <section
        className=" bg-image"
        style={{
          backgroundImage: 'url("/img/malecon-buenaventura.webp")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="mask d-flex align-items-center gradient-custom-3">
          <div className="container pt-5">
            <div className="row d-flex justify-content-center align-items-center ">
              <div className="col-12 col-md-9 col-lg-7 col-xl-7">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    <h2 className="text-uppercase text-center mb-3">
                      Crear cuenta
                    </h2>
                    <form
                      onSubmit={nextBooking}
                      className="row g-3 justify-content-md-center"
                    >
                      <div className="col-md-6">
                        <label className="form-label">Nombres</label>
                        <div className="control">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="First Name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Apellidos</label>
                        <div className="control">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Email</label>
                        <div className="control">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <label className="form-label">Numero de Telefono</label>
                        <div className="control">
                          <input
                            className="form-control"
                            type="tel"
                            placeholder="Contact Number"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">
                          Tipo de Identificacion
                        </label>
                        <div className="control">
                          <div className="control">
                            <select
                              id="typeId"
                              className="form-control"
                              name="typeId"
                              value={typeId}
                              onChange={(e) => setTypeId(e.target.value)}
                            >
                              <option value="choose" selected>
                                Choose one option
                              </option>
                              <option value="CC">Cedula de Ciudadania</option>
                              <option value="CE">Cedula de Extranjeria</option>
                              <option value="PP">Pasaporte</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">
                          Numero de Identificacion
                        </label>
                        <div className="control">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="DNI"
                            name="numId"
                            value={numId}
                            onChange={(e) => setNumId(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">Pais</label>
                        <div className="control">
                          <Select
                            options={countries}
                            name="country"
                            onChange={(e) => setCountry(e.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">Ciudad</label>
                        <div className="control">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="City"
                            name="city"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <label className="form-label">Direccion</label>
                        <div className="control">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Address"
                            name="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="form-label">
                          Numero Contacto de Emergencia
                        </label>
                        <div className="control">
                          <input
                            className="form-control"
                            type="tel"
                            placeholder="Emergency Contact Number"
                            name="emergency"
                            value={emergency}
                            onChange={(e) => setEmergency(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-check d-flex justify-content-center mb-2">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          id="agree"
                          name="agree"
                          onClick={(e) => setAgree(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="agree">
                          Acepto,{" "}
                          <a
                            href="#!"
                            onClick={handleOpen}
                            className="text-body"
                          >
                            <u className="text-info">Terminos y Condiciones</u>
                          </a>{" "}
                          DisfrutaBuenaventura
                        </label>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                          id="submitButton"
                        >
                          Siguiente
                        </button>
                      </div>
                      <p className="text-center text-muted mt-2 mb-0">
                        Ya tienes cuenta?{" "}
                        <Link to={"/login"} className="fw-bold text-info">
                          Inicia Sesion
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ mt: 2 }}
          >
            Terminos y Condiciones
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ¿Qué tipo de agencia somos?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Disfruta Buenaventura centro de información turística y agencia de
            viajes es una agencia de viajes operadora constituida en Colombia,
            con número de NIT 1006190813-8 y RNT 88207. DISFRUTA BUENAVENTURA,
            es un centro de información y operador receptivo turístico del
            Distrito de Buenaventura, que busca conectar viajeros con las
            comunidades, mediante experiencias turísticas en un entorno natural
            y cultural que aporte al desarrollo socioeconómico y sostenible de
            los territorios. Nuestra agencia ha sido constituida en el sur
            occidente de Colombia, en el departamento del Valle del Cauca,
            Buenaventura Distrito Especial.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Aceptación de términos y condiciones
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Nuestros viajeros aceptan todos los términos y condiciones en los
            siguientes casos:
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Cuando realiza la reserva total con el pago del 100% de la
            experiencia y/o servicio turístico. Firma una cotización, boucher o
            cualquier documento requerido por la agencia para confirmar la
            compra de uno de nuestros servicios. Si realizó el procedimiento de
            reserva por la página web, también indica que tuvo acceso a la
            información de términos y condiciones, razón por la cual ya es
            responsable de haber obtenido la información y está sujeto a cumplir
            con lo estipulado en los términos y condiciones. Una vez el viajero
            haga lectura de lo estipulado por la agencia de viajes DISFRUTA
            BUENAVENTURA, está aceptando con claridad la información y en caso
            de no entender una condición o no estar de acuerdo con ella, debe
            presentar sus inquietudes al correo info@disfrutabuenaventura.com
            donde presente de manera clara su caso, ahora bien esto se debe dar
            por lo menos con 7 días anterior al caso.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Requisitos del cliente
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Nuestros viajeros/clientes asumen la responsabilidad de todo lo
            estipulado por la agencia de viajes DISFRUTA BUENVENTURA, en los
            términos y condiciones a la edad de 18 años, que en Colombia se
            asume como mayor de edad; si es menor de edad debe estar acompañado
            por un adulto familiar, poseer un permiso de sus padres. El
            viajero/cliente padece alguna enfermedad, es dependiente de algún
            medicamento o sufre alguna discapacidad, es muy importante que nos
            brinde esa información documentada, además es vital que nos brinden
            un documento de condición médica, DISFRUTA BUENAVENTURA no se hace
            responsable por información alterada. El seguro médico para viajes
            es obligatorio, de no obtenerlo la agencia de viajes lo incluye
            dentro de sus servicios.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Servicios que ofrecemos
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            DISFRUTA BUENAVENTURA trabaja con la comunidad local de los
            atractivos o destinos turísticos como principales aliados, ellos son
            los principales responsables de la operación del viaje,
            <br /> DISFRUTA BUENAVENTURA funciona como intermediaria, promotora
            y comercializadora de los servicios ofrecidos.
            <br /> DISFRUTA BUENAVENTURA también opera sus propios servicios de
            manera directa y se hace responsable de la ejecución de las
            experiencias turísticas.
            <br /> Nuestros clientes se comunican con nosotros para obtener
            nuestros servicios por medio del correo electrónico, página web y
            redes sociales.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reserva
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Para realizar una reserva en DISFRUTA BUENAVENTURA el
            viajero/cliente debe realizarla llenando el formato de reservas en
            la página web, también lo puede hacer por medio de una llamada
            telefónica donde puede realizarla con ayuda de un asesor de ventas.
            En caso del que el cliente/viajero ya haya abonado para realizar la
            reserva y se vaya a concretar la venta del servicio, si no cumple
            con los requisitos mínimos establecidos para realizarla, estamos en
            el derecho a no concretarla. <br />
            Las reservas se pueden separar con el 30% del valor total del
            servicio siempre y cuando sea una compra para una fecha posterior
            mínimo a 15 días.
            <br />
            Si la experiencia se llevará a cabo dentro una fecha posterior a los
            8 días, con se puede separar la reserva con un 50% del valor total
            del servicio.
            <br />
            Si la experiencia se lleva a cabo en una fecha menor a los 8 días de
            la fecha acordada de viaje, con anterioridad el viajero/cliente debe
            pagar el 100% valor total del plan.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cancelación o replanificación del viaje por parte del cliente
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            La cancelación o re-planificación de viajes se debe hacer como
            mínimo con 30 días de anticipación, de lo contrario DISFRUTA
            BUENAVENTURA se encuentra en la obligación de retener el 30% de los
            dineros pagados por el cliente/viajero, siempre y cuando él no tenga
            una excusa que se pueda validar o esté autenticada.
            <br />
            Cuando el cliente/viajero por razones externas a DISFRUTA
            BUENAVENTURA no está en el lugar de encuentro y a la hora estipulada
            para comenzar un viaje, la agencia no se hace responsable, hace el
            reembolso del dinero que crea necesario al cliente/viajero, no está
            obligado a devoluciones, a menos que el cliente/viajero tenga una
            razón de fuerza mayor, que debe ser remitida por medio de pólizas de
            cancelación del servicio.
            <br />
            DISFRUTA BUENAVENTURA permite al CLIENTE/VIAJERO re-planificar su
            viaje siempre y cuando sea anticipado a los 30 días, debe sujetarse
            a los servicios que la agencia tenga disponible en el momento y
            saber que se atiene a posiblemente poner más dinero, si el viaje
            actual es más costoso que el viaje anterior. La nueva reserva está
            sujeta a las condiciones de DISFRUTA BUENAVENTURA.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cancelación o replanificación del viaje por parte de la agencia
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Para la mayoría de las experiencias / planes turísticos en DISFRUTA
            BUENAVENTURA es necesario que se cumpla con el cupo mínimo de
            participantes, sino 5 días anteriores al viaje se cancelará el plan
            y se hará la devolución del 100% del monto que haya pagado hasta la
            fecha. DISFRUTA BUENAVENTURA no se hace responsable de cualquier
            pago a servicios externos a la agencia (entradas a conciertos,
            parques, eventos, alquiler de autos, etc.) que el viajero/cliente
            haya hecho por eso recomendamos formar una póliza de cancelación de
            viajes.
            <br />
            DISFRUTA BUENAVENTURA da la opción a los viajeros/clientes con el
            consentimiento de todos, una vez ya se les haya confirmado que los
            cupos para la experiencia/planes están incompletos, pagar un monto
            adicional si considera hacer el viaje. También otra opción que
            ofrece DISFRUTA BUENAVENTURA es abonar el dinero pagado de la
            reserva anterior o pedir que se le reembolse la totalidad del monto
            pagado por la experiencia.
            <br />
            Si por motivos de una situación fortuita por parte de un
            viajero/cliente o un tercero, que se considere de fuerza mayor,
            DISFRUTA BUENAVENTURA podrá modificar las condiciones del viaje o
            cancelarlo antes o durante este. En caso de que la agencia desee
            cancelar un viaje solo devuelve el dinero de los servicios que la
            persona no ha disfrutado hasta el momento.
            <br />
            DISFRUTA BUENAVENTURA podrá hacer modificaciones en sus servicios en
            la plataforma o en el portafolio de servicios sin previo aviso,
            cuando haya situaciones externas a la agencia que así lo demanden,
            tales como; desastres naturales, alzas en los precios, condiciones
            climáticas, cierre de parques, reservas, centros de eventos, cierre
            de vías entre otras situaciones que se puedan presentar.
            <br />
            Habrá modificaciones en caso de presentarse algún indicio o
            situación de seguridad antes o durante la experiencia, en este caso
            DISDFRUTA BUENAVENTURA no figura como el agente que incumple, dará
            la opción de mantener el modelo establecido entre cliente/viajero y
            agencia garantizado lo que en el inicio se acordó una vez haya otras
            condiciones favorables.
            <br />
            DISFRUTA BUENAVENTURA tendrá derecho a retener el 15% de un servicio
            que se cancele antes de haber iniciado por situaciones inesperadas,
            fuerza mayor o por acto de un tercero.
            <br />
            La cancelación por parte de DISFRUTA BUENAVENTURA una vez iniciado
            el viaje por situaciones inesperadas, fuerza mayor o por acto de un
            tercero, solo hará devoluciones por los servicios no adquiridos por
            el viajero/cliente, y tampoco será responsable de los costos
            adicionales que haya incurrido. Artículo 16 de la Ley 1480 de 2011.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cancelación y/o modificación por parte de la agencia después de
            iniciados
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            En tales casos o situaciones DISFRUTA BUENAVENTURA debe realizar la
            devolución de dinero al CLIENTE/VIAJERO, que se hará efectivo 30
            días después de:
            <br />
            Que el VIAJERO/CLIENTE proporcione la información documentada de los
            datos para la transacción bancaria, documento de identidad. El
            VIAJERO/CLIENTE debe asumir los costos de las transferencias en
            bancos nacionales e internacionales.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Precio, depósito y pago final
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
            Obligaciones del cliente al pagar, número de cuotas, tiempo de pago,
            confirmación de pago, avisar que los precios pueden variar.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Inclusiones en los servicios
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
            Dejar claro que solo incluye lo que está en la oferta y no se hace
            responsable de adicionales.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Inclusión de vehículos y/o equipos de servicio
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
            Aplicar aquí que los precios varían según el vehículo, que se debe
            tener la documentación adecuada por el alquiler, debe pagar un
            depósito, etc. REVISAR ESTE PORQUE NO BRINDAMOS ESTE SERVICIO.
            Vehículo y /o equipo propio
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Vehículo y /o equipo propio
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
            También pueden cumplir con toda la reglamentación menos con el
            depósito y no nos hacemos responsable en caso de accidentes, robos o
            daños.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Hospedaje del cliente
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
            Hay que especificar los tipos de acomodación que tenemos y también
            si el cliente requiere uno diferente y ya no hay cupos para ese tipo
            de acomodación no nos hacemos cargo de gastos adicionales y éste
            decide tomar ese tipo de acomodación.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Exclusiones de precios
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
            Recalcar que el precio que se pone no va en lo de “no se incluye”.
            Equipaje
            <br /> No nos haremos responsable de pérdida de equipaje, recomendar
            póliza de seguro para el equipaje. Propiedad material multimedia
            <br /> El derecho de publicar material audiovisual que queramos sin
            requerir permiso de los clientes que salgan en ella.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Propiedad material multimedia
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
            El derecho de publicar material audiovisual que queramos sin
            requerir permiso de los clientes que salgan en ella.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default FormAddUserClient;
