import React, { useState, useEffect } from "react";
import AuthUser from "../../features/AuthUser";
import Swal from "sweetalert2";
import Select from "react-select";
import { Avatar } from "@mui/material";

const FormEditClient = () => {
  const { http, user } = AuthUser();
  const fullname = user.name + " " + user.last_name;
  const id = user.uuid;
  const [idc, setIdc] = useState("");

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [typeId, setTypeId] = useState("");
  const [numId, setNumId] = useState("");
  const [country, setCountry] = useState();
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [emergency, setEmergency] = useState("");

  const [countries, setCountries] = useState([]);

  const [live, setLive] = useState("");
  const [iso, setIso] = useState("");

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await http.get("/countries");
      const data = response.data.countries;

      const options = data.map((d) => ({
        value: d.id,
        label: d.name,
      }));
      setCountries(options);
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const getClientId = async () => {
      try {
        const response = await http.get("/clients/edit");
        setName(response.data.name);
        setLastName(response.data.last_name);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setTypeId(response.data.type_id);
        setNumId(response.data.dni);
        setCountry(response.data.country_id);
        setCity(response.data.city);
        setAddress(response.data.address);
        setEmergency(response.data.emergency);
      } catch (error) {
        if (error.response) {
          console.log(error);
        }
      }
    };
    getClientId();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await http.get(`/clients/${id}/show`);
      setLive(response.data.country);
      setIso(response.data.iso);
      setIdc(response.data.idc);
    };
    fetchData();
  });

  const updateClient = async (e) => {
    e.preventDefault();
    try {
      await http
        .put(`/clients/${id}/${idc}`, {
          name: name,
          last_name: lastName,
          phone: phone,
          type_id: typeId,
          country_id: country,
          city: city,
          address: address,
          emergency: emergency,
        })
        .then(() => {
          Swal.fire("OK", "Client data Updated Successfully", "success");
        });
    } catch (error) {
      Swal.fire({
        text: "Error, data cannot be recorded",
        icon: "error",
      });
    }
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-4 col-xl-4">
          <div className="card mb-3">
            <div className="card-header">
              <h5 className="card-title mb-0">Profile Details</h5>
            </div>
            <div className="card-body text-center">
              <Avatar
                alt={fullname}
                className="avatar-profile"
                {...stringAvatar(fullname)}
              />
              <h5 className="card-title mb-0">{fullname}</h5>
              <div className="text-muted mb-2">{user.role}</div>
            </div>
            <hr className="my-0" />
            <div className="card-body">
              <h5 className="h6 card-title">About</h5>
              <ul className="list-unstyled mb-0">
                <li className="mb-1">
                  <span data-feather="home" className="feather-sm me-1" /> Lives
                  in {live}, {iso}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-8 col-xl-8">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Actualizar Datos</h5>
            </div>
            <div className="card-body h-100">
              <form
                onSubmit={updateClient}
                className="row g-3 justify-content-md-center"
              >
                <div className="col-md-6">
                  <label className="form-label">Nombres</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Apellidos</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Email</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-6">
                  <label className="form-label">Numero de Telefono</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Contact Number"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Tipo de Identificacion</label>
                  <div className="control">
                    <div className="control">
                      <select
                        id="typeId"
                        className="form-control"
                        name="typeId"
                        value={typeId}
                        onChange={(e) => setTypeId(e.target.value)}
                      >
                        <option value="choose" selected>
                          Choose one option
                        </option>
                        <option value="CC">Cedula de Ciudadania</option>
                        <option value="CE">Cedula de Extranjeria</option>
                        <option value="PP">Pasaporte</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Numero de Identificacion</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="DNI"
                      name="numId"
                      value={numId}
                      onChange={(e) => setNumId(e.target.value)}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <label className="form-label">Pais</label>
                  <div className="control">
                    <Select
                      options={countries}
                      name="country"
                      value={country}
                      onChange={(e) => setCountry(e.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <label className="form-label">Ciudad</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="City"
                      name="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-4">
                  <label className="form-label">Direccion</label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <label className="form-label">
                    Numero Contacto de Emergencia
                  </label>
                  <div className="control">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Emergency Contact Number"
                      name="emergency"
                      value={emergency}
                      onChange={(e) => setEmergency(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormEditClient;
