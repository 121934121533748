import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import AuthUser from "../../features/AuthUser";
import { LineChart, Line } from "recharts";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";

const MonthlyReport = () => {
  const { http } = AuthUser();
  const [bookings, setBookings] = useState([]);
  const [partners, setPartners] = useState([]);

  const columns = [
    {
      name: "code",
      label: "Code",
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "last_name",
      label: "LastName",
    },
    {
      name: "checkin",
      label: "Check-In",
    },
    {
      name: "checkout",
      label: "Check-Out",
    },
    {
      name: "destiny",
      label: "Destiny",
    },
    {
      name: "total",
      label: "Total",
    },
    {
      name: "status",
      label: "Status",
    },
  ];

  const columnsPartners = [
    {
      name: "typeId",
      label: "Type ID",
    },
    {
      name: "numId",
      label: "No ID",
    },
    {
      name: "name",
      label: "First Name",
    },
    {
      name: "last_name",
      label: "Last Name",
    },
  ];

  const options = { filterType: "checkbox" };

  const [destiny, setDestiny] = useState([]);


  useEffect(() => {
    const getBookings = async () => {
      const response = await http.get("/monthly");
      setBookings(response.data);
    };
    getBookings();
  }, []);

  useEffect(() => {
    const getPartners = async () => {
      const response = await http.get("/quantityPeople");
      setPartners(response.data);
      console.log(response.data);
    };
    getPartners();
  }, []);

  useEffect(() => {
    const getDestiny = async () => {
      await http.get("/destiny").then((response) => {
        setDestiny(response.data);
      });
    };
    getDestiny();
  }, []);

  return (
    <>
      <h1 className="h3 mb-3">Tables</h1>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Bookings</h5>
            </div>
            <div className="card-body">
              <MUIDataTable
                title={"Reservas"}
                data={bookings}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Visitors</h5>
            </div>
            <div className="card-body">
              <MUIDataTable
                title={"Viajeros"}
                data={partners}
                columns={columnsPartners}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MonthlyReport;
